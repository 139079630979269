import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import "../ProductPage/subcomponents/Product/product.css";
import LoadingBar from 'react-top-loading-bar'
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import Header from '../../../layout/Header/Header';
import AddReview from '../../../components/Modal/AddReview/AddReview';
import { GlobalContext } from '../../../store/GlobalContext';
import Footer from '../../../layout/Footer/Footer'
import "./MyOrders.css"
import image from '../../../assets/images/webp/Frame-31.webp'
import SearchAndGenderSection from '../../../components/SearchAndGenderSection/SearchAndGenderSection';
export default function MyOrders() {
    const { state, dispatch } = useContext(GlobalContext);
    const [showPage, setShowPage] = useState(true)
    const [progress, setProgress] = useState(100)
    const navigate = useNavigate();
    const filteredData = state.orderItems;
    const location = useLocation();
    const [showAlert, setShowAlert] = useState(false);
    const [showModel, setShowModel] = useState(false)
    const [reviewProductId, setReviewProductId] = useState('')
    const [reviewOrderId, setReviewOrderId] = useState('')
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    useEffect(() => {
        const values = QueryString.parse(location.search);
        if (values.success) {
            setShowAlert(true)
        }
        if (values.canceled) {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleShowModel = (orderId, productId) => {
        setReviewProductId(productId);
        setReviewOrderId(orderId);
        setShowModel(true)
    }
    function handleDatesDispatching() {
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (dateRegex.test(startDate) && dateRegex.test(endDate)) {
            const ordersApiParams = { ...state.ordersApiParams }
            ordersApiParams.startDate = startDate;
            ordersApiParams.endDate = endDate;
            dispatch({
                type: "ORDERS_API_PARAMS",
                payload: ordersApiParams
            })
        } else {
            alert("please provide valid dates")
        }
    }
    return (
        <>
            {showAlert && <div className="alert alert-success alert-dismissible position-relative fade show mx-auto alert-style" role="alert">
                Your order has been placed <strong>successfully!</strong>
                <i className="fa-solid fa-xmark" onClick={() => setShowAlert(false)}></i>
            </div>
            }
            <Header />
            <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
            <AddReview setShowModel={setShowModel} showModel={showModel} orderId={reviewOrderId} productId={reviewProductId} />
            {showPage &&
                <>

                    <SearchAndGenderSection />

                    <section id='orders'>
                        <div className='row px-lg-5 px-sm-0 mx-3'>
                            <div className='card'>
                                <div className='p-4'>
                                    <h2 className='text-uppercase m-0'>ORDERS</h2>
                                </div>
                                <div className='d-flex justify-content-around mb-3' style={{ width: window.screen.width < 500 && '1254px' }}>
                                    <div className="flex" >
                                        <span className='mx-2'>Filter Date:</span>
                                        <input value={startDate} onChange={(e) => setStartDate(e.target.value)} type='date' style={{ paddingLeft: '25px', borderRadius: '0.4rem', position: 'relative' }} />
                                        <i class="fa fa-calendar calender"></i>
                                        <span className='mx-2 mt-2'>to</span>
                                        <input value={endDate} onChange={(e) => setEndDate(e.target.value)} type='date' style={{ borderRadius: '0.4rem' }} />
                                        <i class="fa fa-calendar calender"></i>
                                        <button className='btn btn-primary mx-2' onClick={() => handleDatesDispatching()}>GO</button>
                                    </div>
                                    <form className="search" action="#">
                                        <input value={state.ordersApiParams.trackingId} onChange={(e) => {
                                            const ordersApiParams = { ...state.ordersApiParams }
                                            ordersApiParams.trackingId = e.target.value
                                            dispatch({
                                                type: "ORDERS_API_PARAMS",
                                                payload: ordersApiParams
                                            })
                                        }
                                        } type="text" className="search-input" placeholder="Search tracking #" id='searchText' name={state.ordersApiParams.trackingId} />
                                        <i className="fa fa-search"></i>
                                    </form>
                                </div>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th scope="col">TRACKING #</th>
                                            <th scope="col" >PRODUCT DETAILS</th>
                                            <th scope="col" >QTY</th>
                                            <th scope="col" >ORDER TOTAL</th>
                                            <th scope="col" >DATE & TIME</th>
                                            <th scope="col" >ORDER STATUS</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            filteredData && Object.values(filteredData)?.map((obj) => {
                                                return (
                                                    <>
                                                        {
                                                            obj?.orders?.map((elem, index) => {
                                                                return (
                                                                    <>
                                                                        <tr key={index}>
                                                                            <td
                                                                                style={{ color: "#E86669", cursor: 'pointer' }}
                                                                                onClick={() => {
                                                                                    navigate(`/Trackorders/${elem?.buyer_tracking_no && elem?.buyer_tracking_no}`)
                                                                                }}
                                                                            >{elem?.buyer_tracking_no}</td>
                                                                            <td>
                                                                                <div className='d-flex'>
                                                                                    <img
                                                                                        onClick={() => navigate(`/${elem?.gender === "MALE" ? "product_display_page_men" : "product_display_page_women"}/${elem.product_url_link}`)}
                                                                                        className='img-fluid me-3' src={elem?.product_image} alt='' style={{ width: "80px", height: "100px", borderRadius: "10px", cursor: 'pointer' }} />
                                                                                    <div className='d-flex flex-column justify-content-center'>
                                                                                        <p className=''>Order # : {elem?.order}</p>
                                                                                        <p
                                                                                            style={{ cursor: 'pointer' }}
                                                                                            onClick={() => navigate(`/${elem?.gender === "MALE" ? "product_display_page_men" : "product_display_page_women"}/${elem.product_url_link}`)}
                                                                                            className='mb-0'>{elem?.product_name}</p>
                                                                                        <p className='mb-0'
                                                                                        >Seller name: <span
                                                                                            onClick={() => navigate(`/seller/${elem?.product_seller}`)}
                                                                                            style={{ color: "#f47274", cursor: "pointer" }}>{elem?.product_seller}</span></p>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td >{elem?.quantity}</td>
                                                                            <td >S${elem?.product_price}</td>
                                                                            <td >{elem?.date_created}</td>
                                                                            <td className='d-flex flex-column align-items-center justify-content-center' style={{ marginTop: (!elem?.rating && elem?.order_status !== "COMPLETED") && "2rem" }}>
                                                                                <span className="badge badgeInit rounded-pill text-dark text-center" style={{ backgroundColor: `${elem.order_status == 'READY TO DISPATCH' ? '#F7ECD0' : '#FAD3DF'}` }}>{elem?.order_status}</span>

                                                                                {!elem?.rating && elem?.order_status === "COMPLETED" &&
                                                                                    <button type="button" className="btn-ninja" onClick={() => handleShowModel(elem?.order, elem?.product)}>
                                                                                        Add Review
                                                                                    </button>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                {Object.values(filteredData)?.length === 0 &&
                                    <div className='no-data'>
                                        <img src={image} alt="logo" className='data-display' />
                                    </div>}
                            </div>
                        </div>
                    </section>
                </>
            }
            <Footer />
        </>
    )
}