import React from 'react'
import bnfImg from "../../../../../assets/images/png/demo-img.png"
import "./Benefits.css"
import { useTranslation } from 'react-i18next';

const Benefits = () => {
    const { t } = useTranslation('about');
    return (
        <section id="benefits">
            <div className="container-fluid" data-aos="fade-up">
                <div className="row mb-5 px-lg-5 px-sm-5 mx-3">
                    <div className="col-lg-7">
                        <div className="section-title">
                            <h2>{t('heading2')}</h2>
                            <p className="lh-base pe-5">{t('p2')}</p>
                        </div>
                        <ul>
                            <li><i className="fa-regular fa-circle-check"></i>{t('text1')}</li>
                            <li><i className="fa-regular fa-circle-check"></i>{t('text2')}</li>
                            <li><i className="fa-regular fa-circle-check"></i>{t('text3')}</li>
                            <li><i className="fa-regular fa-circle-check"></i>{t('text4')}</li>
                            <li><i className="fa-regular fa-circle-check"></i>{t('text5')}</li>
                        </ul>
                    </div>
                    <div className="col-lg-4" style={{ marginTop: window.screen.width < 500 ? "0" : '-8rem' }}>
                        <div className="benefit-img">
                            <img src={bnfImg} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Benefits