import React from 'react'
import avatar from "../../../../assets/images/png/avatar.png"
import { useContext, useEffect, useState } from "react";
import axios from "../../../../services/api/axios";
import Cookies from "universal-cookie";
import MyAlert from "../../../../components/Alerts/Alert/alert";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import { GlobalContext } from "../../../../store/GlobalContext";
import "./ProfileDetails.css"
import { useTranslation } from 'react-i18next';
const cookies = new Cookies();
const ProfileDetails = () => {

    const { state, dispatch } = useContext(GlobalContext);
    const [error, setError] = useState('')
    const { t } = useTranslation('userprofile')
    const [edit, setEdit] = useState(true);
    const [fullName, setFullName] = useState('');
    const [gender, setGender] = useState('');
    const [userName, setUserName] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [image, setImage] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [preUsername, setPreUsername] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageList, setImageList] = useState([]);
    const [validated, setValidated] = useState(false);
    const [text, setText] = useState('')
    useEffect(() => {
        // Update state when the API response arrives
        if (state.user) {
            setFullName(state.user.fullName || '');
            setGender(state.user.gender || '');
            setUserName(state.user.userName || '');
            setAddress(state.user.address || '');
            setEmail(state.user.email || '');
            setPhoneNumber(state.user.phoneNo || '');
            setImage(state.user.image || '');
            setDateOfBirth(state.user.dateOfBirth || '');
            setPreUsername(state.user.userName || '');
            setSelectedImage(state.user.image || null);
        }
    }, [state.user]);
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImageList(file)
        if (file) {
            // You can use FileReader to read the selected image and set it in the state
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const HandleSave = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidated(true);
            setText('')
            if (fullName?.length < 5 || address?.length < 8) {
                setText("Please make sure that fullname has minimum 5 characters and address has 8 characters")
            }
            else {
                const imageFile = new FormData();
                imageFile.append("image", image);

                const commonBody = {
                    image: imageList,
                    fullName: fullName,
                    phoneNo: phoneNumber,
                    address: address,
                    dateOfBirth: dateOfBirth,
                    gender: gender
                };

                const body = userName === preUsername
                    ? { ...commonBody }
                    : { ...commonBody, userName: userName };
                await axios.patch("user/update_user/", body,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${cookies.get('access')}`,
                            'Accept-Language': state.language
                        },
                        withCredentials: true
                    }).then(res => {
                        setText('Updated Successfully')
                        setEdit(true)
                    }).catch(err => {
                        setError(err.code)
                    })
            }
        }
    }
    useEffect(() => {
        if (edit) {
            dispatch({
                type: "REFRESH_PROFILE",
                payload: !state.refreshProfile
            })
        }
    }, [edit])
    return (
        <>
            <section id="add_product">
                <div className="row justify-content-between px-lg-5 px-sm-5 mx-3 my-5">
                    <div className='card'>
                        {/* <MyAlert text={text} setText={setText} /> */}
                        {text && <div className="alert alert-success alert-dismissible fade show" role="alert" style={{ height: "4rem" }}>
                            <p className="errmsg" aria-live="assertive">{text}</p>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setText('')}></button>
                        </div>
                        }
                          {error && <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ height: "4rem" }}>
                            <p className="errmsg" aria-live="assertive">{error}</p>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setError('')}></button>
                        </div>
                        }
                        <h2 className='mb-5'>{t('heading1')}</h2>
                        <div className='row'>
                            <Form className="col-lg-9 pe-lg-5" noValidate validated={validated}>
                                <div className="row g-3 mb-5">
                                    <h4 className=''>{t('heading2')}</h4>
                                    <p>{t('text1')}</p>
                                    <Form.Group as={Col} className="col-lg-6 col-md-6 col-12 mb-3" controlId="validationCustom02">
                                        <Form.Label>{t('label1')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="form-label"
                                            minLength={6}
                                            value={fullName}
                                            name={fullName}
                                            onChange={(e) => setFullName(e.target.value)}
                                            disabled={edit}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">{t('error1')}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="col-lg-6 col-md-6 col-12 mb-3" controlId="validationCustomUsername">
                                        <Form.Label>{t('label2')}</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="text"
                                                className="form-label"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                minLength={4}
                                                name={userName}
                                                value={userName}
                                                onChange={(e) => setUserName(e.target.value.replace(/ +?/g, ''))}
                                                disabled={edit}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {t('error2')}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} className="col-lg-6 col-md-6 col-12 mb-3" controlId="validationCustom03">
                                        <Form.Label>{t('label3')}</Form.Label>
                                        <Form.Select
                                            name={gender}
                                            value={gender}
                                            className="form-select form_clr category-field"
                                            onChange={(e) => {
                                                setGender(e.target.value)
                                            }}
                                            disabled={edit}
                                            required={gender === "MALE" || gender === "FEMALE"}>
                                            <option>{t('option1')}</option>
                                            <option value="MALE">{t('option2')}</option>
                                            <option value="FEMALE">{t('option3')}</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {t('error3')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="col-lg-6 col-md-6 col-12 mb-3" controlId="validationCustom04">
                                        <Form.Label>{t('label4')}</Form.Label>
                                        <Form.Control
                                            type="date"
                                            className="form-control input_bar"
                                            value={dateOfBirth}
                                            name={dateOfBirth}
                                            onChange={(e) => setDateOfBirth(e.target.value)}
                                            disabled={edit}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {t('error4')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="row g-3">
                                    <h4 className=''>{t('heading3')}</h4>
                                    <p>{t('text2')}</p>
                                    <Form.Group as={Col} className="col-lg-6 col-md-6 col-12 mb-3" controlId="validationCustom05">
                                        <Form.Label>{t('label5')}</Form.Label>
                                        <Form.Control
                                            type="email"
                                            className="form-control input_bar"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            disabled
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {t('error5')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="col-lg-6 col-md-6 col-12 mb-3" controlId="validationCustom06">
                                        <Form.Label>{t('label6')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="form-control input_bar"
                                            value={phoneNumber}
                                            name={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9]/g, ""))}
                                            disabled={edit}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {t('error6')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="col-12  mb-3" controlId="validationCustom07">
                                        <Form.Label>{t('label7')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="form-control input_bar "
                                            minLength={10}
                                            aria-label="Address"
                                            aria-describedby="basic-addon1"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            disabled={edit}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {t('error7')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </Form>
                            <div className="col-lg-3 mt-3 px-5">
                                {/* <div className='add_image mb-4'>
                                    <img className='img-fluid w-100' src={addImage} alt="" />
                                </div> */}
                                <div className='profile-image-wrapper mb-5'>
                                    <img className='profile-image img-fluid rounded-circle w-100' src={selectedImage || avatar} alt="User Profile" />
                                    <label className="btn btn-secondary rounded-circle btn-upload">
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange}
                                            accept="image/*"
                                            disabled={edit}
                                        />
                                        <i className="fa-solid fa-camera fa-lg"></i>
                                    </label>
                                </div>
                                <div className='text-center'>
                                    <button type="button" className="btn btn-profile text-uppercase mb-3">{t('btn1')}</button>
                                    {edit
                                        ? <button type="button" onClick={() => setEdit(false)} className="btn btn-profile text-uppercase mb-3">{t('btn2')}</button>
                                        : <><button type="button" onClick={HandleSave} className="btn btn-profile text-uppercase mb-3">{t('btn3')}</button>
                                            <button type="button" onClick={() => { setValidated(false); setEdit(true) }} style={{ borderRadius: "25px", padding: "0.5rem", width: "-webkit-fill-available" }} className="btn btn-secondary text-uppercase mb-3">{t('btn4')}</button>
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default ProfileDetails