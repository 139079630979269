import { useEffect, useState } from "react";
// import "./UserProfile.css";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Cookies from "universal-cookie";
import Form from 'react-bootstrap/Form';
import optionsData from "../../../../utils/constants/options";
import LoadingBar from 'react-top-loading-bar'
import MyAlert from "../../../../components/Alerts/Alert/alert";
const cookies = new Cookies();
const SellerEditProfile = (props) => {
    const [setLoader] = useState();
    const [refresh] = useState(false);
    const [setShowPage] = useState(false)
    const [progress, setProgress] = useState(30)
    const axiosPrivate = useAxiosPrivate();
    const sizeOptions = (optionsData.size);
    const raceOptions = (optionsData.race);
    const [countryOPAPI, setCountryOPAPI] = useState([]);
    const [bodyType, setBodyType] = useState("");
    const [country, setCountry] = useState("");
    const [gender, setGender] = useState("");
    const [race, setRace] = useState("");
    const [bio, setBio] = useState();
    const [image, setImage] = useState();
    const [images, setImages] = useState();
    const [dateOfBirth, setDateOfBirth] = useState();
    const [text, setText] = useState();
    const onLoad = () => {
        //method for button times
        var group1 = document.getElementById("group3");
        group1.classList.remove('hide');
    }

    useEffect(() => {
        axiosPrivate.get("product_country_list/",
        )
            .then(res => {
                setCountryOPAPI(res.data)
            }).catch(err => {
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh])
    const countryOptions = countryOPAPI.map((obj) => {
        return {
            label: obj.country_name,
            value: obj.country_name
        }
    });
    useEffect(() => {
        axiosPrivate.get("seller/seller_profile/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            setBodyType(res.data[0].bodyType)
            setCountry(res.data[0].country)
            setGender(res.data[0].gender)
            setRace(res.data[0].race)
            setBio(res.data[0].sellerDescription)
            setImages(res.data[0].image)
            setDateOfBirth(res.data[0].dateOfBirth)
            setProgress(100)
            setShowPage(true)
        }).catch(err => {
        })

        var fileTag = document.getElementById("file-input"),
            preview = document.getElementById("preview");

        fileTag.addEventListener("change", function () {
            changeImage(this);
        });

        function changeImage(input) {
            var reader;

            if (input.files && input.files[0]) {
                reader = new FileReader();

                reader.onload = function (e) {
                    preview.setAttribute('src', e.target.result);
                }

                reader.readAsDataURL(input.files[0]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [axiosPrivate])
    const HandleCancel = () => {
        props.onChange(!props.show)
    }
    const calculate_age = (dob1) => {
        var today = new Date();
        var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        return age_now;
    }
    const HandleSave = async () => {
        const imageFile = new FormData();
        imageFile.append("image", image)
        const age = calculate_age(dateOfBirth)
        if (age > 20) {
            const body = {
                image: image,
                age: age,
                bodyType: bodyType,
                gender: gender,
                race: race,
                country: country,
                sellerDescription: bio,
                dateOfBirth: dateOfBirth
            }
            await axiosPrivate.patch("seller/update_seller_profile/", body,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }).then(res => {
                    props.onChange(!props.show)
                }).catch(err => {
                    setText(err.code)
                })
        } else {
            setText("please ensure you are 21 or above")
            setLoader(false)
        }
    }
    return (
        <>
            <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
            {/* {showPage === true && <> */}
            <div className="Seller-Main-page ">

                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-10 col-11 mx-auto ">
                            <MyAlert text={text} setText={setText} />
                            <div className=" row  Seller-Personal_detail">
                                <h2 className="mb-3">Account</h2>
                                <div className="d-flex justify-content-between position-relative">

                                    <div className="form-text col-lg-8 col-md-8 col-6 mb-3 me-2 text-wrap">

                                        <h6 id="Seller-Pro" className="">Profile</h6>
                                        This information will be displayed publicaly so be careful what you share
                                    </div>


                                    <div className=" img-area position-absolute">
                                        <div className="profile-pic position-relative">
                                            {
                                                !images &&
                                                <div className="">
                                                    <span className=" d-flex flex-column text-center  Upload-container" >
                                                        <i className="fas fa-camera"></i>

                                                        <small>Upload Photo</small>
                                                    </span>
                                                </div>
                                            }
                                            <div className="profilepic">
                                                <img src={images} alt="" id="preview" />

                                                <div className=" profilepic__content " id="group3" onClick={onLoad}>

                                                    <label htmlFor="file-input">
                                                        <span className="profilepic__icon d-flex flex-column text-center " >
                                                            <i className="fas fa-camera"></i>

                                                            <small>Upload Photo</small>
                                                        </span>
                                                    </label>

                                                    <input id="file-input" type="file"
                                                        onChange={(event) => {
                                                            setImage(event.target.files[0]);
                                                        }} />
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-lg-4  col-6  mb-3">

                                    <label htmlFor="age" className="form-label"><strong>Age</strong></label>
                                    <input
                                        type="number"
                                        className="form-control input_bar"
                                        id="inputEmail4"
                                        value={age}
                                        name={age}
                                        onChange={(e) => setAge(e.target.value)} />

                                </div> */}

                                <div className="col-lg-4 col-6  mb-3">

                                    <label htmlFor="inputEmail4" className="form-label"><strong>BodyType</strong></label>
                                    <select
                                        value={bodyType ? bodyType : ""}
                                        className="form-select form_clr category-field"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onChange={(e) => {
                                            setBodyType(e.target.value)
                                        }}>
                                        {sizeOptions.map(
                                            (obj, index) => (
                                                <option key={index} value={obj.value} className="dropdown-list" style={{ backgroundColor: "#060c22" }}>{obj.label}
                                                </option>
                                            )
                                        )}
                                    </select>

                                </div>



                                <div className="col-lg-4 col-12  mb-3">

                                    <label htmlFor="inputEmail4" className="form-label"><strong>Gender</strong></label>
                                    <select
                                        value={gender ? gender : ""}
                                        id="inputState"
                                        className="form-select form_clr category-field"
                                        onChange={(e) => {
                                            setGender(e.target.value)
                                        }}
                                    >
                                        <option selected>Choose...</option>
                                        <option value="MALE">Male</option>
                                        <option value="FEMALE">Female</option>
                                    </select>

                                </div>

                                <hr className="my-4" />

                                <div>
                                    <h6>Personal information</h6>

                                    <div className="form-text mb-3">
                                        This information will be displayed publicly so be careful what you share
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <label htmlFor="inputEmail4" className="form-label"><strong>Race</strong></label>
                                    <select
                                        value={race ? race : ""}
                                        className="form-select form_clr category-field "
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onChange={(e) => {
                                            setRace(e.target.value)
                                        }}>
                                        {raceOptions.map(
                                            (obj, index) => (
                                                <option key={index} value={obj.value} className="dropdown-list" style={{ backgroundColor: "#060c22" }}>{obj.label}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <label htmlFor="inputEmail4" className="form-label"><strong>Country</strong></label>
                                    <select
                                        type="text"
                                        value={country ? country : ""}
                                        className="form-select form_clr category-field "
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onChange={(e) => {
                                            setCountry(e.target.value)
                                        }}
                                    >
                                        <option value="" className="dropdown-list" style={{ backgroundColor: "#060c22" }}>Country
                                        </option>
                                        {countryOptions.map(
                                            (obj, index) => (
                                                <option key={index} value={obj.value} className="dropdown-list" style={{ backgroundColor: "#060c22" }}>{obj.label}
                                                </option>
                                            )
                                        )}
                                    </select>
                                    {/* <AddCountry refresh={refresh} setRefresh={setRefresh} /> */}
                                </div>


                                <div className="col-lg-4 col-md-12 mb-3">
                                    <label htmlFor="inputEmail4" className="form-label"><strong>Birthday</strong></label>
                                    <input
                                        type="date"
                                        className="form-control input_bar"
                                        id="inputEmail4"
                                        value={dateOfBirth ? dateOfBirth : ""}
                                        name={dateOfBirth}
                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                    />
                                </div>
                                <label htmlFor="exampleFormControlTextarea1" className="form-label"><strong>Description</strong></label>
                                <Form.Group
                                    className=""
                                    controlId="exampleForm.ControlTextarea1"
                                    name={bio}
                                    id="message"
                                    required>
                                    <Form.Control as="textarea" onChange={(e) => setBio(e.target.value)} value={bio ? bio : ""} className="form_clr" rows={3} placeholder="Write your messeage here..." />
                                </Form.Group>
                                {/* <div className="col-lg-6 col-md-6 mb-3">
                                <label htmlFor="inputEmail4" className="form-label">Gender</label>

                                <select id="inputState" className="form-select Pick_input mt-1">
                                    <option selected>Choose...</option>
                                    <option value="gsg">Male</option>
                                    <option value="">Female</option>
                                </select>
                            </div> */}

                                {/* <div className="col-lg-4 col-md-4 mb-3">
                                <label htmlFor="inputEmail4" className="form-label">Country</label>
                                <input type="text" className="form-control input_bar" id="inputEmail4"
                                    onChange={(e) => setCountry(e.target.value)} />
                            </div> */}




                                <hr className="my-4" />
                                <div className="d-flex justify-content-end ">
                                    <button onClick={HandleCancel} className="btn btn-outline-secondary  me-2" id="btn-btn">Cancel</button>
                                    <button onClick={HandleSave} className="btn btn-save" >Save</button>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </div>
            {/* </>
            } */}
        </>
    )
}

export default SellerEditProfile;