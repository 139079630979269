import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import axios from '../../../../services/api/axios';
import { useNavigate } from 'react-router-dom';
import './ArticlesCards.css'
import { useTranslation } from 'react-i18next';
import { extractMonthAndDay } from '../../../../utils/helpers/helpers'

const ArticlesCards = () => {
    const { t } = useTranslation('articles')
    const [articles, setArticles] = useState([]);
    const navigate = useNavigate()
    useEffect(() => {
        axios.get('view-blogs/')
            .then(res => {
                console.log(res.data)
                setArticles(res?.data?.blogs)
            }).catch(err => {
                console.log(err)
            })
    }, [])
    const maxLength = 80;
    const [expanded, setExpanded] = useState(false);
    const toggleDescription = () => {
        setExpanded(!expanded);
    };
    return (
        <section id='articles-page'>
            <div className="articles-hero">
                <div className="hero-container" data-aos="zoom-in" data-aos-delay="100">
                    <div className="row container-fluid">
                        <div className="col-lg-12 text-left py-5 ps-5">
                            <h2 className="pb-0">{t('heading1')}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row px-5 mx-3 mb-5' style={{ marginTop: "-2rem" }}>
                <div className='col-lg-9'>
                    <div className='row'>
                        {articles?.map(elem => (
                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" style={{ cursor: "pointer" }} onClick={() => navigate(`/new/view-articles/${elem?.uuid}`)}>
                                <div className="member" data-aos="fade-up" data-aos-delay="100">
                                    <div className="member-img">
                                        <img src={elem?.thumbnail} className="img-fluid image-item" alt="" />
                                        <div className='date'>
                                            <p className='mb-0'>{extractMonthAndDay(elem?.created_at)?.month}</p>
                                            <p className='mb-0'>{extractMonthAndDay(elem?.created_at)?.day}</p>
                                        </div>
                                    </div>
                                    <div className="member-info">
                                        <h5>{elem?.title}</h5>
                                        <p>{expanded ? elem?.description : elem?.description?.length > maxLength ? `${elem?.description?.slice(0, maxLength)}... ` : elem?.description}
                                            {elem?.description?.length > maxLength && (
                                                <button className="toggle-btn" onClick={toggleDescription} style={{ background: "none", border: "none", color: "#F47274", padding: "0", width: "auto", fontSize: "1vw" }}>
                                                    {expanded ? 'Read less' : 'Read more'}
                                                </button>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-5 p-5">
                    <h4 className='px-3 mb-4'>{t('heading2')}</h4>
                    <p className='px-3'>{t('text1')}</p>
                    <p className='px-3'>{t('text2')}</p>
                    <p className='px-3'>{t('text3')}</p>
                </div>

            </div>

            {/* <div className='row' >
                <div className='col-8' style={{ marginTop: "-2rem" }}>
                    <div className='container mb-4'>
                        <div className='row d-flex justify-content-center mx-5'>
                            {articles?.map(elem => {
                                return (
                                    <>
                                        <div className="card col-5 m-2" style={{ cursor: "pointer" }} onClick={() => navigate(`/new/view-articles/${elem?.uuid}`)}>
                                            <img className="card-img-top" src={elem?.thumbnail} alt="Card image" style={{ width: '100%', height: '300px' }} />
                                            <div className="card-body">
                                                <h5 className="card-title">{elem?.title}</h5>
                                                <p className="card-text">
                                                    {elem?.description}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='col-4 mt-4' style={{ color: "black" }}>
                    <h3>Recent Articles</h3>
                    <p>7 September 2023</p>
                    <p>24 Sep 2023</p>
                    <p>24 August 2023</p>
                </div>
            </div> */}



        </section>


    )
}

export default ArticlesCards