import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Dropdown from 'react-bootstrap/Dropdown';
import { useContext } from "react";
import AuthContext from "../../../../../context/AuthProvider";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
const cookies = new Cookies();
export default function Headermain() {
    const isLoggedIn = window.localStorage.getItem("isLoggedIn")
    const axiosPrivate = useAxiosPrivate();
    const [userName, setUserName] = useState("");
    const [image, setImage] = useState();
    useEffect(() => {
        axiosPrivate.get("user/user_profile/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            setUserName(res?.data[0]?.userName)
            setImage(res?.data[0]?.image)
        }).catch(err => {
        })
    }, [axiosPrivate])
    const { setAuth } = useContext(AuthContext);
    const logout = async () => {
        setAuth({});
        window.localStorage.setItem("isLoggedIn", "false");
    }
    return (
        <header id="header" className="d-flex align-items-right ">
            <div className="container-fluid container-xxl d-flex justify-content-end align-items-end">
                <div className="me-5">
                    <div className="rounded-top" role="group" aria-label="Basic outlined example">
                        {isLoggedIn === "true"
                            ? <div className="btn-group col-lg-3 col-md-3" >
                                <Dropdown className='user_drop ms-lg-2 ms-2  order-lg-3 order-md-3 order-3'>
                                    <Dropdown.Toggle variant="" id="dropdown-basic">

                                        {image
                                            ? <span className='buyer-profile-img'><img src={image} alt="not found" style={{ width: "", height: "" }} /></span>
                                            : <i className="fa-solid fa--circle--user  ms-3"></i>
                                        }
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu id="my_dropdown" className='shadow p-2'>
                                        <Dropdown.Item as={Link} to="/Profile" className='myWishlist mb-1'>{userName}</Dropdown.Item>
                                        <hr className='my-2' />
                                        <Dropdown.Item as={Link} to="/Dash" className='myWishlist mb-1'>Switch to Seller</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/Myorders" className='myWishlist mb-1' >Orders</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/Wishlist" className='myWishlist mb-1' >Wishlist</Dropdown.Item>

                                        <Dropdown.Item as={Link} to="/Profile" className='Profile mb-1' >Profile</Dropdown.Item>
                                        <Dropdown.Item onClick={() => logout()} className='Sign_out '>Sign Out</Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            : <div className="btn-group btn-btn-group" role="group">
                                <Link to="/Login" type="button" className="btn btn-outline button-btn">Log In</Link>
                                <Link to="/Signup" type="button" className="btn btn-outline button-btn">Sign Up</Link>
                            </div>
                        }
                    </div>
                </div>

            </div>
        </header>
    )

}