import React from 'react'
import Header from '../../../layout/Header/Header'
import Footer from '../../../layout/Footer/Footer'
import ArticlesCards from './subcomponents/ArticlesCards'

const Articles = () => {
    return (
        <div>
            <Header />
            <ArticlesCards />
            <Footer />
        </div>
    )
}

export default Articles