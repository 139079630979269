import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../../layout/Header/Header';
import Footer from "../../../../layout/Footer/Footer";
import './Payment.css'
import Summary from '../Suummary/Summary';
import { GlobalContext } from '../../../../store/GlobalContext';
import stripe from '../../../../assets/images/png/Stripe.png'
import Rectangle63 from '../../../../assets/images/png/Rectangle63.png'
import axios from '../../../../services/api/axios';
import Cookies from 'universal-cookie';
import $ from 'jquery'
import { Form } from 'react-bootstrap';
const cookies = new Cookies();
const Payment = () => {
    const { state, dispatch } = useContext(GlobalContext)
    const Navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState(null);
    const [fullName, setFullName] = useState(state?.user?.fullName);
    const [username, setUsername] = useState(state?.user?.userName);
    const [address, setAddress] = useState(state?.user?.address);
    const [email, setEmail] = useState(state?.user?.email);
    const [phone, setPhone] = useState(state?.user?.phoneNo);
    const [validated, setValidated] = useState(false);
    var sufiId;
    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };
    useEffect(() => {
        const checkoutInfo = { ...state?.checkoutInfo, payment_method: selectedOption }
        dispatch({
            type: "CHECKOUT_INFO",
            payload: checkoutInfo
        })
    }, [selectedOption])
    const handlePaymentSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true)
            return;
        }
        if (!selectedOption) {
            alert("please choose a payment method first!")
            return;
        }
        let body = state.checkoutInfo;
        body.buyer_email = email;
        body.buyer_fullName = fullName;
        body.shippingAddress = address;
        body.buyer_username = username;
        body.buyer_phone_no = phone
        if (selectedOption === "paynow") {
            axios.post('product/checkout/', body, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }).then(res => {
                console.log(res, 'res')
                window.location.replace(res?.data?.url);
            }).catch(err => {
                console.log(err, 'err')
            })
        } else if (selectedOption === "stripe") {
            axios.post("product/checkout/", body,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            ).then(res => {
                sufiId = res?.data?.id
                $("#sufi").attr('action', `${process.env.REACT_APP_BASE_URL}/create-checkout-session/` + res.data.id + '/');
                $("#sufi").submit()
            }).catch(err => {
            })
        };
    }
    return (
        <>
            <Header />
            <section id="breadcrumb">
                <nav className='row px-lg-5 mx-lg-3' aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {/* <li className="breadcrumb-item text-dark opacity-5"><Link to="/">SHOP</Link></li> */}
                        <li className="breadcrumb-item text-dark active" aria-current="page">{`HOME >  SHOP > I’M INTERESRED IN WOMENS CLOTHES > CART > Checkout`}</li>
                    </ol>
                </nav>
            </section>
            <section id='payment'>
                <div className='row px-lg-5 px-sm-0 mx-3'>
                    <div className='col-lg-8'>
                        <h4 className='mb-3'>Shipping & Billing Information</h4>
                        <p className='mb-4'>For NinjaVan delivery, please make sure to enter your COMPLETE ADDRESS.</p>
                        <Form noValidate validated={validated} onSubmit={handlePaymentSubmit} id='sufi'
                            action={`${process.env.REACT_APP_BASE_URL}/create-checkout-session/${sufiId}/`}
                            method='POST'>
                            <div className="row">
                                <div className='col-lg-8'>
                                    <Form.Group className="color-filed" controlId="exampleForm.ControlInput1">
                                        <Form.Control
                                            type="email"
                                            autoComplete="off"
                                            className="form-control"
                                            name="email"
                                            value={email}
                                            placeholder="name@example.com"
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="color-filed" controlId="exampleForm.ControlInput2">
                                        <Form.Control
                                            placeholder="Complete Name"
                                            type="text"
                                            autoComplete="off"
                                            className="form-control mt-3"
                                            value={fullName}
                                            onChange={(e) => setFullName(e.target.value)}
                                            aria-describedby="uidnote"
                                            pattern={'^.{3,}$'}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid fullname.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="color-filed" controlId="exampleForm.ControlInput3">
                                        <Form.Control
                                            placeholder="Username"
                                            type="text"
                                            autoComplete="off"
                                            className="form-control mt-3"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            aria-describedby="uidnote"
                                            pattern={'^.{3,}$'}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid username.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="color-filed" controlId="exampleForm.ControlInput4">
                                        <Form.Control
                                            onChange={(e) => setAddress(e.target.value)}
                                            type="text" className="form-control mt-3"
                                            value={address}
                                            name="address"
                                            placeholder="Complete Address"
                                            pattern={'^.{11,}$'}
                                            required />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid address.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="color-filed" controlId="exampleForm.ControlInput4">
                                        <Form.Control
                                            onChange={(e) => setPhone(e.target.value)}
                                            type="tel" className="form-control mt-3"
                                            value={phone}
                                            name="phone"
                                            placeholder="Phone Number"
                                            required />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid address.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <label className='mt-3' htmlFor="paymentOptions">Choose mode of payment:</label>
                                    <div className='d-flex'>
                                        <div className={`payment-option ${selectedOption === 'stripe' ? 'selected' : ''} me-2`} onClick={() => handleOptionChange('stripe')}>
                                            <img src={stripe} alt="Stripe" />
                                        </div>

                                        <div className={`payment-option ${selectedOption === 'paynow' ? 'selected' : ''}`} onClick={() => handleOptionChange('paynow')}>
                                            <img src={Rectangle63} alt="PayNow" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex my-5'>
                                <button className='btn-cancel me-3' type='button' onClick={() => Navigate('/checkout')}>CANCEL</button>
                                <button className='btn-proceed' type='submit'>PROCEED TO PAY</button>
                            </div>

                        </Form>
                    </div>
                    <div className='col-lg-4'>
                        <Summary />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Payment