import './form.css'
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { useState, useEffect, useContext } from "react";
import Cookies from "universal-cookie";
import { Link, useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import LoadingBar from 'react-top-loading-bar'
import "./form.css"
import { GlobalContext } from '../../../../../store/GlobalContext';
import Summary from '../../Suummary/Summary';
const cookies = new Cookies();
const FormBody = (props) => {
    const { state, dispatch } = useContext(GlobalContext)
    const [showPage, setShowPage] = useState(true)
    const Navigate = useNavigate();
    const [dots] = useState(false)
    const [progress, setProgress] = useState(100)
    const axiosPrivate = useAxiosPrivate();
    const [loaderId, setLoaderId] = useState('')
    const [postalCode, setPostalCode] = useState("");
    const [lockers, setLockers] = useState([])
    const cartData = state.cartItems
    const [bool, setBool] = useState(false);
    const [locker_station_id, setLocker_station_id] = useState("");
    const [deliveryOptions, setDeliveryOptions] = useState(state.checkoutInfo?.delivery_method);
    const [loader, setLoader] = useState(false)
    const [blockNumber, setBlockNumber] = useState('')
    const [country_code, setCountry_code] = useState('')
    const [building_name, setBulding_name] = useState('')
    const [text, setText] = useState('');
    const [postalError, setPostalError] = useState('');
    const [communityClubsChecked, setCommunityClubsChecked] = useState(false);
    const [hDBsChecked, setHDBsChecked] = useState(false);
    const [mrtStationsChecked, setMRTStationsChecked] = useState(false);
    const [filteredArray, setFilteredArray] = useState([]);

    const handleCommunityClubsChange = () => {
        setCommunityClubsChecked(!communityClubsChecked);
    };

    const handleHDBsChange = () => {
        setHDBsChecked(!hDBsChecked);
    };

    const handleMRTStationsChange = () => {
        setMRTStationsChecked(!mrtStationsChecked);
    };
    useEffect(() => {
        if (locker_station_id) {
            let checkoutInfo = { ...state.checkoutInfo }
            for (let i = 0; i < lockers?.length; i++) {
                if (lockers[i]?.locker_station_id === locker_station_id) {
                    checkoutInfo.lockerStationId = locker_station_id
                    checkoutInfo.lockerStationAddress = `${lockers[i]?.block_number} ${lockers[i]?.building_name} ${lockers[i]?.country_code} ${postalCode}`
                    dispatch({ type: 'CHECKOUT_INFO', payload: checkoutInfo });
                }
            }
        }
    }, [locker_station_id])
    useEffect(() => {
        if (postalCode?.length > 5) {
            const checkoutInfo = { ...state.checkoutInfo, postalCode };
            dispatch({ type: 'CHECKOUT_INFO', payload: checkoutInfo });
        }
    }, [postalCode])
    useEffect(() => {
        if (hDBsChecked === true && communityClubsChecked === false && mrtStationsChecked === false && lockers) {
            const tempArr = [...lockers]
            const tempArr2 = tempArr.filter(temp => temp.building_name == 'HDB')
            setFilteredArray(tempArr2)
        }
        if (hDBsChecked === false && communityClubsChecked === true && mrtStationsChecked === false && lockers) {
            const tempArr = [...lockers]
            const tempArr2 = tempArr.filter(temp => temp.building_name.includes("Community Club"))
            setFilteredArray(tempArr2);
        }
        if (hDBsChecked === false && communityClubsChecked === false && mrtStationsChecked === true && lockers) {
            const tempArr = [...lockers]
            const tempArr2 = tempArr.filter(temp => temp.building_name.includes("MRT" || "BUS" || "INTERCHANGES"))
            setFilteredArray(tempArr2);
        }
        if (hDBsChecked === true && communityClubsChecked === true && mrtStationsChecked === false && lockers) {
            const tempArr = [...lockers]
            const tempArr2 = tempArr.filter(temp => temp.building_name == 'HDB')
            const tempArr3 = tempArr.filter(temp => temp.building_name.includes("Community Club"));
            setFilteredArray(tempArr2.concat(tempArr3));
        }
        if (hDBsChecked === true && communityClubsChecked === false && mrtStationsChecked === true && lockers) {
            const tempArr = [...lockers]
            const tempArr2 = tempArr.filter(temp => temp.building_name == 'HDB')
            const tempArr3 = tempArr.filter(temp => temp.building_name.includes("MRT" || "BUS" || "INTERCHANGES"));
            setFilteredArray(tempArr2.concat(tempArr3));
        }
        if (hDBsChecked === false && communityClubsChecked === true && mrtStationsChecked === true && lockers) {
            const tempArr = [...lockers]
            const tempArr2 = tempArr.filter(temp => temp.building_name.includes("Community Club"))
            const tempArr3 = tempArr.filter(temp => temp.building_name.includes("MRT" || "BUS" || "INTERCHANGES"));
            setFilteredArray(tempArr2.concat(tempArr3));
        }
        if (hDBsChecked === true && communityClubsChecked === true && mrtStationsChecked === true && lockers) {
            const tempArr = [...lockers]

            setFilteredArray(tempArr);
        }
        if (hDBsChecked === false && communityClubsChecked === false && lockers) {
            setFilteredArray([]);
        }

    }, [hDBsChecked, communityClubsChecked, mrtStationsChecked])
    console.log('FILTERED ARRAY----', filteredArray)
    const handleLockers = () => {
        setPostalError("")
        if (!postalCode) {
            setPostalError("Enter Postal Code first")
            setLoader(false)
        } else if (postalCode.length < 6) {
            setPostalError("Postal code must be 6 digit")
            setLoader(false)
        } else {
            axiosPrivate.get("interop/get_lockers_location/",
                {
                    params: {
                        country_code: 'sg',
                        postal_code: postalCode,
                    }
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            ).then(res => {
                if (res) {
                    setLockers(res?.data?.locker_station_details)
                    setBlockNumber(res?.data?.locker_station_details[0]?.block_number)
                    setBulding_name(res?.data?.locker_station_details[0]?.building_name)
                    setCountry_code(res?.data?.locker_station_details[0]?.country_code)
                }
                else {
                    setPostalError("Lockers not found")
                }
                setLoader(false)
            }).catch(err => {
                console.log(err.code)
                setLoader(false)
                setText(err)
            })
        }
    }

    const handlePick = () => {
        axiosPrivate.post("interop/lockers_token/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            if (res?.status === 200) {
                handleLockers();
            }
        }).catch(err => {
            setText(err.code)
        })
    }

    useEffect(() => {
        let checkoutInfo = { ...state.checkoutInfo }
        checkoutInfo.delivery_method = deliveryOptions;
        dispatch({ type: 'CHECKOUT_INFO', payload: checkoutInfo });
    }, [deliveryOptions])
    const RemoveFromCart = async (id) => {
        setLoaderId(id)
        await axiosPrivate.delete(`product/remove_from_cart/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            })
            .then(res => {
                dispatch({
                    type: "REFRESH_CART",
                    payload: !state.refreshCart
                })
                props.onChange(!props.checkCart)
                setBool(!bool)
                setLoaderId("")
            }).catch(err => {
                setText(err.code)
            })
    }
    const UpdateCart = async (id, qty, add) => {
        setLoaderId(id)
        let body;
        if (add == 1) {
            body = {
                quantity: qty + 1
            }
        } else {
            body = {
                quantity: qty - 1
            }
        }
        await axiosPrivate.patch(`product/update_cart_item/${id}/`, body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            })
            .then(res => {
                setBool(!bool)
                setLoaderId("")
            }).catch(err => {
                setText(err.code)
            })
    }
    const handleProceedToPay = async () => {
        if (deliveryOptions === "PICK" && !locker_station_id) {
            alert("You have not selected any locker yet")
        }
        else {
            Navigate('/payment');
        }
    }


    return (

        <>
            <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
            {showPage === true && !dots && <>

                <section id="breadcrumb">
                    <nav className='row px-lg-5 mx-3' aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item text-dark opacity-5"><Link to="/">SHOP</Link></li>
                            <li className="breadcrumb-item text-dark active" aria-current="page">I’m interested in Mens Clothes</li>
                        </ol>
                    </nav>
                </section>
                {cartData.length !== 0 ? <div>
                    <section id='checkout'>
                        <div className='row px-lg-5 px-sm-5 mx-3'>
                            <div className='col-lg-8 checkout-table'>
                                <table className="table table-borderless">
                                    <thead className="table-dark curved-header">
                                        <tr>
                                            <th scope="col" className='text-center'>No.</th>
                                            <th scope="col" >Product Name</th>
                                            <th scope="col" className='text-center'>QTY</th>
                                            <th scope="col" className='text-center'>Price</th>
                                            <th scope="col" className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartData?.map((elem, index) => {
                                            let itemNo = 0;
                                            return (<>
                                                {elem?.items?.map(obj => {
                                                    itemNo += 1;
                                                    return (
                                                        <tr key={itemNo}>
                                                            <th scope="row" className='text-center'>{itemNo}</th>
                                                            <td>
                                                                <div className='d-flex'>
                                                                    <img className='img-fluid me-3' src={obj?.image} alt='' style={{ width: window.screen.width > 500 ? "80px" : '29px', height: window.screen.width > 500 && "100px", borderRadius: "10px", objectFit: window.screen.width > 500 ? "cover" : "contain" }} />
                                                                    <div className='d-flex flex-column justify-content-center'>
                                                                        <p className='mb-0'>{obj?.product_name}</p>
                                                                        <p className='mb-0' style={{ color: "#f47274" }}>{elem?.seller_name}{elem.is_verified && (<i className="fa-solid fa-circle-check fa-lg p-3"></i>)}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className='text-center'>{obj?.quantity}</td>
                                                            <td className='text-center'>S${parseFloat(obj?.price).toFixed(2)}</td>
                                                            <td className='text-center'><i onClick={() => RemoveFromCart(obj.cart_id)} className="fa-regular fa-trash-can fa-2xl"></i></td>
                                                        </tr>
                                                    )
                                                })}
                                            </>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-lg-4'>
                                <Summary />

                            </div>
                        </div>
                    </section>
                    <section id='shipping'>
                        <div className='row px-lg-5 px-sm-5  mx-3'>
                            <h5>Shipping & Delivery</h5>
                            <p className='mb-4'>Choose your preferred delivery option.</p>
                            <div className='d-flex mb-4'>
                                <button onClick={() => setDeliveryOptions('PICK')} className={`${deliveryOptions === 'PICK' ? 'btn-locker-ship' : "btn-ninja"} me-3`} >Pick Locker</button>
                                <button onClick={() => setDeliveryOptions('NINJA')} className={`${deliveryOptions === 'NINJA' ? 'btn-locker-ship' : "btn-ninja"}`} >Ninja Van</button>
                            </div>
                            {deliveryOptions === "PICK" &&
                                <div>
                                    <div className="card card-body">
                                        <div className='row mb-4'>
                                            <div className='col-lg-3'>
                                                <div className="search">
                                                    <input
                                                        type="text"
                                                        className="search-input"
                                                        placeholder="Enter postal code"
                                                        id='searchText'
                                                        name="searchKeyword"
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                handlePick();
                                                            }
                                                        }}
                                                        onChange={(e) => setPostalCode(e.target.value)}
                                                    />
                                                    <i className="fa fa-search"></i>
                                                    {/* <input className="search-btn" type="submit" value="products" /> */}
                                                </div>
                                            </div>
                                            <div className='col-lg-9 d-flex align-items-center justify-content-around'>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input"
                                                        type="checkbox"
                                                        id="inlineCheckbox1"
                                                        value="option1"
                                                        checked={communityClubsChecked}
                                                        onChange={handleCommunityClubsChange} />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox1" style={{ fontSize: '0.7rem' }}>Community Clubs</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input"
                                                        type="checkbox"
                                                        id="inlineCheckbox2"
                                                        value="option2"
                                                        checked={hDBsChecked}
                                                        onChange={handleHDBsChange} />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox2" style={{ fontSize: '0.7rem' }}>HDBs</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input"
                                                        type="checkbox"
                                                        id="inlineCheckbox3"
                                                        value="option3"
                                                        checked={mrtStationsChecked}
                                                        onChange={handleMRTStationsChange} />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox3" style={{ fontSize: '0.7rem' }}>MRT Stations/ Bus Interchanges</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>

                                            {
                                                ((hDBsChecked || communityClubsChecked || mrtStationsChecked) && filteredArray.length === 0) ? (
                                                    <Alert variant="" style={{ color: "black", backgroundColor: "#f6ecd0" }} className='mt-4 '>
                                                        <h6 className="mb-0 p-3" style={{ fontWeight: "500" }}>
                                                            No Postal Address Found.
                                                        </h6>
                                                    </Alert>
                                                ) : filteredArray.length > 0 ? (
                                                    filteredArray.map((elem, index) => (
                                                        <div className='col-lg-4'>
                                                            <div className='card postal position-relative'>
                                                                <span className="badge bg-success px-3">{elem?.building_name}</span>
                                                                <h6>{elem?.locker_station_description}</h6>
                                                                <ul>
                                                                    <li className='d-flex'>
                                                                        <i className="fa-solid fa-location-dot mt-1"></i>
                                                                        <div>
                                                                            <p className='mb-0'>Singapore {postalCode}</p>
                                                                            <p className='mb-0' style={{ color: "#A5A5A5" }}>Near {elem?.street_name}</p>
                                                                        </div>
                                                                    </li>
                                                                    <li><i className="fa-regular fa-clock"></i>{elem?.opening_hours}</li>
                                                                </ul>
                                                                <div className='text-center'>
                                                                    <button
                                                                        className={elem?.locker_station_id === locker_station_id ? 'btn-locker' : 'btn-select'}
                                                                        onClick={() => setLocker_station_id(elem?.locker_station_id)}
                                                                    >{elem?.locker_station_id === locker_station_id ? "SELECTED" : 'SELECT'}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    lockers?.map((elem, index) => (
                                                        <div className='col-lg-4'>
                                                            <div className='card postal position-relative'>
                                                                <span className="badge bg-success px-3">{elem?.building_name}</span>
                                                                <h6>{elem?.locker_station_description}</h6>
                                                                <ul>
                                                                    <li className='d-flex'>
                                                                        <i className="fa-solid fa-location-dot mt-1"></i>
                                                                        <div>
                                                                            <p className='mb-0'>Singapore {postalCode}</p>
                                                                            <p className='mb-0' style={{ color: "#A5A5A5" }}>Near {elem?.street_name}</p>
                                                                        </div>
                                                                    </li>
                                                                    <li><i className="fa-regular fa-clock"></i>{elem?.opening_hours}</li>
                                                                </ul>
                                                                <div className='text-center'>
                                                                    <button
                                                                        className={elem?.locker_station_id === locker_station_id ? 'btn-locker' : 'btn-select'}
                                                                        onClick={() => setLocker_station_id(elem?.locker_station_id)}
                                                                    >{elem?.locker_station_id === locker_station_id ? "SELECTED" : 'SELECT'}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='d-flex my-5'>
                                <button className='btn-ninja me-3' onClick={() => Navigate('/product_page_men')}>CANCEL</button>
                                <button className='btn-locker' onClick={() => handleProceedToPay()}>PROCEED TO CHECKOUT</button>
                            </div>
                        </div>
                    </section></div> :
                    <>
                        <span>
                            <Alert variant="" style={{ color: "black", backgroundColor: "#f6ecd0", margin: '4rem' }} className='mt-4 '>
                                <h6 className="mb-0 p-3" style={{ fontWeight: "500" }}>
                                    No Items In Cart. Please navigate to <Link to={"/product_page_men"} className="ALert-msg"><strong>products page</strong></Link>.
                                </h6>
                            </Alert>
                        </span>
                    </>}
            </>
            }
        </>
    )
}

export default FormBody;