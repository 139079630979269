import React, { useEffect, useState } from 'react'
import "../Dashboard/Dashboard.css"
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import DashboardHeader from '../Dashboard/subcomponents/DashboardHeader';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Cookies from 'universal-cookie';
import EditProfileModel from '../../../components/Modal/EditProfileModel/EditProfileModel';
import LoadingBar from 'react-top-loading-bar'
const cookies = new Cookies();
export default function SellerViewProfile() {
    const [showPage, setShowPage] = useState(false)
    const [progress, setProgress] = useState(30)
    const axiosPrivate = useAxiosPrivate();
    const [bodyType, setBodyType] = useState("");
    const [country, setCountry] = useState("");
    const [gender, setGender] = useState("");
    const [race, setRace] = useState("");
    const [bio, setBio] = useState();
    const [image, setImage] = useState();
    const [dateOfBirth, setDateOfBirth] = useState();
    const [userName, setUserName] = useState();
    const [isVerified, setIsVerfied] = useState();
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        axiosPrivate.get("seller/seller_profile/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            setUserName(res.data[0].userName)
            setBodyType(res.data[0].bodyType)
            setImage(res.data[0].image)
            setRace(res.data[0].race)
            setDateOfBirth(res.data[0].dateOfBirth)
            setBio(res.data[0].sellerDescription)
            setGender(res.data[0].gender)
            setCountry(res.data[0].country)
            setIsVerfied(res.data[0].isVerified)
            setProgress(100)
            setShowPage(true)
        }).catch(err => {
        })
    }, [refresh, axiosPrivate])
    const calculate_age = (dob1) => {
        var today = new Date();
        var birthDate = new Date(dob1);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        return age_now;
    }
    return (
        <>
            <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
            {showPage === true && <>
                <DashboardHeader />
                <div className='container ' id='Seller-View'>
                    <div className='row'>
                        <div className='Seller-main shadow  p-5 '>
                            <div className='col-lg-12'>

                                <div className='d-flex justify-content-between'>

                                    <div className='d-flex'>
                                        <h2>Profile</h2>

                                    </div>

                                    <div className=''>
                                        <EditProfileModel refresh={refresh} setRefresh={setRefresh} />
                                    </div>


                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-lg-8 order-2'>
                                    <div className='seller-form '>
                                        <form className="row">

                                            <div className="col-lg-6 col-md-6 col-12 mb-3">
                                                <label htmlFor="inputEmail4" className="form-label"><strong>Age</strong></label>
                                                <input
                                                    type="text"
                                                    className="form-control form_clr"
                                                    id="inputEmail4"
                                                    name={calculate_age(dateOfBirth)}
                                                    value={calculate_age(dateOfBirth) ? calculate_age(dateOfBirth) : ""}
                                                    // onChange={(e) => setAge(e.target.value)}
                                                    disabled
                                                />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-12 mb-3">
                                                <label htmlFor="inputEmail4" className="form-label"><strong>Race</strong></label>
                                                <input
                                                    type="text"
                                                    className="form-control form_clr"
                                                    id="inputEmail4"
                                                    name={race}
                                                    value={race ? race : ""}
                                                    // onChange={(e) => setRace(e.target.value)}
                                                    disabled
                                                />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-12 mb-3">
                                                <label htmlFor="inputEmail4" className="form-label"><strong>BodyType</strong></label>
                                                <input
                                                    type="text"
                                                    className="form-control form_clr"
                                                    id="inputEmail4"
                                                    name={bodyType}
                                                    value={bodyType ? bodyType : ""}
                                                    // onChange={(e) => setBodyType(e.target.value)}
                                                    disabled
                                                />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-12 mb-3">
                                                <label htmlFor="inputEmail4" className="form-label"><strong>Gender</strong></label>
                                                <input
                                                    type="text"
                                                    className="form-control form_clr"
                                                    id="inputEmail4"
                                                    name={gender}
                                                    value={gender ? gender : ""}
                                                    // onChange={(e) => setGender(e.target.value)}
                                                    disabled
                                                />
                                            </div>


                                            <div className="col-lg-6 col-md-6 col-12 mb-3">
                                                <label htmlFor="inputEmail4" className="form-label"><strong>Country</strong></label>
                                                <input
                                                    type="text"
                                                    className="form-control form_clr"
                                                    id="inputEmail4"
                                                    name={country}
                                                    value={country ? country : ""}
                                                    // onChange={(e) => setCountry(e.target.value)}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <label htmlFor="inputEmail4" className="form-label"><strong>Birthday</strong></label>
                                                <input
                                                    type="date"
                                                    className="form-control form_clr"
                                                    id="inputEmail4"
                                                    value={dateOfBirth}
                                                    name={dateOfBirth ? dateOfBirth : ""}
                                                    // onChange={(e) => setDateOfBirth(e.target.value)}
                                                    disabled
                                                />
                                            </div>

                                            {/* <h5 style={{marginBottom:4}}>About Me</h5> */}
                                            {/* <p><em>Personalize your store page by telling customers a little about yourself.</em></p> */}


                                            <div className="col-12 mt-3">
                                                <label htmlFor="inputEmail4" className="form-label"><strong>Bio</strong></label>
                                                <Form.Group
                                                    className=""
                                                    controlId="Form.ControlTextarea1"
                                                    name={bio}
                                                    id="message" onChange={(e) => setBio(e.target.value)}
                                                    required>
                                                    <Form.Control as="textarea" value={bio ? bio : ""} rows={3} placeholder="Write your messeage here..." disabled />
                                                </Form.Group>
                                            </div>


                                        </form>
                                    </div>
                                </div>

                                <div className='col-lg-4 order-1  mb-lg-0 mb-4'>
                                    {/* <div className='pic-section p-5'> */}
                                    {/* <h3>Add Image</h3> */}
                                    <div className="Seller-profilepic-about mx-auto mt-5">
                                        <div className="upload__image-wrapper-about">



                                            <div className="image-item">
                                                <img src={image} alt="" />
                                            </div>



                                        </div>

                                    </div>
                                    <div className="col-12 text-center">
                                        <Link to={''} className=" mail-size">{userName}{isVerified && <i className="far fa-check-circle mt-4"></i>}</Link>
                                        {/* <div className="d-flex  justify-content-center   mt-1">
                                            <i className="fa-solid fa-star mt-1"></i>
                                            <i className="fa-solid fa-star mt-1"></i>
                                            <i className="fa-solid fa-star mt-1"></i>
                                            <i className="fa-solid fa-star mt-1"></i>
                                            <i className="fa-solid fa-star mt-1 me-1"></i>
                                            <span>(22)</span>
                                        </div> */}

                                    </div>

                                    {/* </div> */}
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </>
            }

        </>
    );
}

