import React, { useState } from 'react';
import { CloseButton } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import SellerEditProduct from '../../../pages/seller/Products/subcomponents/SellerProductEdit/SellerProductEdit';

const ProductViewModel = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <i className='fa-regular fa-pen-to-square fa-lg p-2' variant="primary" onClick={handleShow}>
            </i>

            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg" className="ps-0">
                <Modal.Body className="p-lg-5 p-md-5 p-3">
                <div style={{textAlign:"right"}}><CloseButton onClick={handleClose} className='btn-close'/></div>
                    <SellerEditProduct 
                    productId={props.productId} 
                    handleClose={handleClose}/>
                    
                </Modal.Body>
            </Modal>
        </>
    );
}
export default ProductViewModel;