import { useState, useEffect } from "react";
import Productsitems from "./subcomponents/Product/products";
import Cookies from "universal-cookie";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";
import { Link } from "react-router-dom";
import "./ProductPage.css";
import SearchNavbar from "../../../components/SearchNavbar/SearchNavbar";
import SearchAndGenderSection from "../../../components/SearchAndGenderSection/SearchAndGenderSection";
const cookies = new Cookies();
const WomenProductPage = () => {
    const [checkCart, setCheckCart] = useState(false);
    const [query, setQuery] = useState("");
    const [searchType, setSearchType] = useState(true)
    const Navigate = useNavigate();
    return (
        <div>
            <Header />
            <SearchAndGenderSection />
            <section id="breadcrumb">
                <nav className='row px-lg-5 mx-3' aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item text-dark opacity-5"><Link to="/">SHOP</Link></li>
                        <li className="breadcrumb-item text-dark active" aria-current="page">I’m interested in Womens Clothes</li>
                    </ol>
                </nav>
            </section>
            <section id="hotels" className="section-with-bg wow fadeInUp">
                <div className="row px-lg-5 mx-3">
                    <Productsitems
                        genderbtn="I'm interested in Female's clothes"
                        btn="about-btn-women scrollto"
                        location="/product_page_men"
                        gender={"FEMALE"}
                        checkCart={checkCart}
                        onChange={value => setCheckCart(value)}
                        query={query}
                        searchType={searchType}
                        setQuery={setQuery}
                        setSearchType={setSearchType}
                    />
                </div>
            </section>
            <Footer />
            <Outlet />
        </div>

    )
}

export default WomenProductPage