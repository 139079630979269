import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from '../../../services/api/axios';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Carts from '../ProductPage/subcomponents/Cart/Carts'
import Header from '../../../layout/Header/Header'
import { Breadcrumb } from "react-bootstrap";
import CustomSearchComponent from '../../../components/CustomSearch/CustomSearchComponent'
const cookies = new Cookies();
function App() {
    // note: the id field is mandatory
    const [data, setData] = useState([]);
    const [searchitems, setSearchItems] = useState();
    const [cartItem, setCartItem] = useState([])
    const [favourites, setFavourites] = useState([]);
    const [isFavourite, setIsFavourite] = useState(false);
    const [cartBool, setCartBool] = useState(false)
    const [loader, setLoader] = useState()
    const axiosPrivate = useAxiosPrivate();
    const { searchQuery } = useParams();
    const [query, setQuery] = useState(searchQuery);
    const [searchType, setSearchType] = useState(true)
    const navigate = useNavigate();
    const [optionButton, setOptionButton] = useState(true)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const mystyle = {
        verticalAlign: "middle",
        fontSize: "1.5rem",
        border: "2px solid #fff",
        padding: "6px",
        borderRadius: "50%",
        color: "#E86669",

    };
    const mystyle1 = {
        verticalAlign: "middle",
        fontSize: "1.5rem",
        border: "2px solid #fff",
        padding: "6px",
        borderRadius: "50%",
        color: "#fff",

    };
    useEffect(() => {
        axiosPrivate.get('search/',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            setSearchItems(res.data)
        }).catch(err => {
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [axiosPrivate])
    useEffect(() => {
        const fetchProducts = async () => {
            await axios.get("/product_list/",
            )
                .then(res => {
                    setData(res.data)
                })
        }
        fetchProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        axiosPrivate.get("product/cart_items_list/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        )
            .then(res => {
                setCartItem(res.data)
            }).catch(err => {
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartBool])
    useEffect(() => {
        axiosPrivate.get("product/favourites_list/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            setFavourites(res.data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFavourite])
    const percentage = (value) => {
        return 20 * value / 100;
    }
    const fun = (id) => {
        for (let i = 0; i < cartItem.length; i++) {
            if (id === cartItem[i].item.id) {
                return true;
            }
        }
        return false;
    }
    const isInFav = (id) => {
        for (let i = 0; i < favourites.length; i++) {
            if (id === favourites[i].products) {
                return true;
            }
        }
        return false;
    }
    const HandleRemoveFromFavourite = async (id) => {
        setLoader(id)
        let favId;
        for (let i = 0; i < favourites.length; i++) {
            if (id === favourites[i].products) {
                favId = favourites[i].id;
            }
        }
        try {
            const response = await axiosPrivate.delete(`product/remove_from_favourite/${favId}/`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            );
            console.log(response, 'response')
            setIsFavourite(prev => !prev)
            setLoader("")
        } catch (err) {
            setLoader("")
        }
    }
    const HandleAddToFavourite = async (id) => {
        setLoader(id)
        try {
            const response = await axiosPrivate.post(`product/add_to_favourite/${id}/`, {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            );
            console.log(response, 'response')
            setIsFavourite(prev => !prev)
            setLoader("")
            //clear state and controlled inputs
            //need value attrib on inputs for this
        } catch (err) {
            setLoader("")
        }
    }
    const AddToCart = async (id) => {
        setLoader(id)
        try {
            const response = await axiosPrivate.post(`/product/add_to_cart/${id}/`, {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            );
            console.log(response, 'response')
            setCartBool(prev => !prev)
            setLoader("")
        } catch (err) {
            setLoader("")
        }
    }
    const sellers = searchitems?.filter((seller) => {
        if (seller?.type === 'seller') {
            return seller?.name.toLowerCase().includes(query.toLowerCase())
        }
        return null;
    }
    ).map((g, index) => {
        if (g.type === 'seller')
            return (
                <>
                    <li style={{ cursor: "pointer" }} onClick={() => navigate(`/seller/${g.url_id}`)} className="list-group-item list-group-item-dark">{g.name}</li>
                </>
            )
        return null;
    })

    const filterData = data.filter((product) =>
        product?.name.toLowerCase().includes(query.toLowerCase())
    ).map((g, index) => {
        return (
            <Carts
                key={index}
                {...g}
                percentage={percentage}
                fun={fun}
                isInFav={isInFav}
                mystyle1={mystyle1}
                mystyle={mystyle}
                loader={loader}
                index={index}
                HandleRemoveFromFavourite={HandleRemoveFromFavourite}
                HandleAddToFavourite={HandleAddToFavourite}
                AddToCart={AddToCart}
            />
        )
    })
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && searchQuery !== '') {
            console.log('Enter key pressed!', searchQuery);
            navigate(`/searchpage/${searchQuery}`)
            // Call your function here
            // ...
        }
    };


    useEffect(() => {
        if (searchType) {
            setOptionButton(true)
        } else {
            setOptionButton(false)
        }
    }, [searchType])
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const searchToggle = () => {
        setOptionButton(!optionButton)
    }

    return (
        <>
            <Header />
            <div className="container App" style={{ marginTop: "2rem" }}>
                <div style={{ marginTop: "11rem", }}>
                    <Breadcrumb className='d-flex justify-content-end align-items-center bread-container mt-lg-3 mt-md-5 mt-4'>
                        <Breadcrumb.Item onClick={() => navigate("/product_page_men")}>Products</Breadcrumb.Item>
                        <Breadcrumb.Item active className='Bread-product me-2'>Search Page</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {windowWidth < 768 &&
                    <>
                        <div onKeyDown={handleKeyDown} style={{ marginTop: "2rem" }}>
                            <CustomSearchComponent
                                searchQuery={query}
                                setSearchQuery={setQuery}
                                searchType={optionButton}
                            />
                        </div>
                        <div className="d-flex justify-content-center " style={{ marginTop: "1rem" }}>
                            <button className="btn border-danger text-danger col-lg-5 col-md-6 col-sm-7 col-8  "
                                onClick={searchToggle}>
                                {optionButton ? "Search User Instead" : "Search Product Instead"}
                            </button>
                        </div>
                    </>
                }
                {!optionButton ? <div className='mt-5'>
                    <h1>Sellers:</h1>
                    {sellers?.length > 0 ?
                        <ul className="list-group">
                            {sellers}
                        </ul>
                        :
                        <div className="alert alert-warning" role="alert">
                            No results found for {query}
                        </div>}</div>
                    :
                    <div className='mt-5'>
                        <h1>Products:</h1>
                        {filterData?.length > 0 ?
                            <div className='row'>
                                {filterData}
                            </div>
                            :
                            <div className="alert alert-warning" role="alert">
                                No results found for {query}
                            </div>}</div>
                }
            </div >
        </>

    )
}

export default App