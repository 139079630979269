import { useReducer } from "react";
import { GlobalContext } from "./GlobalContext";
import { reducer } from "./Reducer";

const initialState = {
    contactRef:null,
    user: {},
    language: "en",
    products: [],
    featuredSellers: [],
    featuredProducts: [],
    cartItems: [],
    favorites: [],
    blogs: [],
    isLoggedIn: false,
    countryList: [],
    categoryList: [],
    orderItems: {},
    refreshProducts: false,
    refreshOrders: false,
    refreshProfile: false,
    refreshFavorites: false,
    refreshCart: false,
    buyerSide: true,
    searchType: "product",
    searchQuery: "",
    productPageOffset: 0,
    ordersApiParams: {
        trackingId: "",
        startDate: "",
        endDate: ""
    },
    productsApiParams: {
        limit: 16,
        offset: 0,
        filterType: "product",
        gender: "MALE",
        searchString: ""
    },
    checkoutInfo: {
        buyer_email: "",
        buyer_fullName: "",
        buyer_phone_no: "",
        payment_method: "",
        delivery_method: "PICK",
        quantity: 0,
        price: 0,
        shipping_price: 0,
        shippingAddress: "",
        orderTotal: 0,
        postalCode: "",
        lockerStationId: "",
        transactionFee: 0,
        lockerStationAddress: ""
    }
}
export const GlobalStateProvider = ({
    children,
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <GlobalContext.Provider value={{ state, dispatch }}>
            {children}
        </GlobalContext.Provider>
    );
};