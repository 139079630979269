import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Header from "../../../layout/Header/Header";
import Cookies from "universal-cookie";
import "../Dashboard/Dashboard.css"
import "../../../pages/buyer/ProductPage/subcomponents/Product/product.css";
import { useParams } from 'react-router-dom'
import LoadingBar from 'react-top-loading-bar'
import Carts from "../../buyer/ProductPage/subcomponents/Cart/Carts";
import ReactPaginate from "react-paginate";
import axios from "../../../services/api/axios";
const cookies = new Cookies();
const SellerProfilePage = () => {
  let { seller } = useParams();
  const [showPage, setShowPage] = useState(true)
  const [progress, setProgress] = useState(100)
  const axiosPrivate = useAxiosPrivate();
  const [productList, setProductList] = useState([]);
  const [loader, setLoader] = useState()
  const [bodyType, setBodyType] = useState("");
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [race, setRace] = useState("");
  // const [setBio] = useState();
  const [isFavourite, setIsFavourite] = useState(false);
  const [cartBool, setCartBool] = useState(false)
  const [image, setImage] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [cartItem, setCartItem] = useState([])
  const [userName, setUserName] = useState();
  const [isVerified, setIsVerfied] = useState();
  const [sellerDescription, setSellerDescription] = useState();
  const [readMore, setReadMore] = useState(false)
  const [favourites, setFavourites] = useState([]);
  // const [setText] = useState();
  const [checkCart, setCheckCart] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageOffset] = useState(0);
  const [itemsPerPage] = useState(20);
  const [searchType, setSearchType] = useState(true)
  const [query, setQuery] = useState();
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = productList?.filter(elem => seller === elem?.seller_name)?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(productList?.filter(elem => seller === elem?.seller_name)?.length / itemsPerPage);

  useEffect(() => {
    axios.get(`buyer/seller-profile/details/?username=${seller}&filter_type=product&limit=&offset`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.get('access')}`
        },
        withCredentials: true
      }
    ).then(res => {
      setUserName(res.data?.seller?.userName)
      setBodyType(res.data?.seller?.bodyType)
      setImage(res.data?.seller?.image)
      setRace(res.data?.seller?.race)
      setDateOfBirth(res.data?.seller?.dateOfBirth)
      // setBio(res.data[0]?.sellerDescription)
      setGender(res.data?.seller?.gender)
      setCountry(res.data?.seller?.country)
      setIsVerfied(res.data?.seller?.isVerified)
      setSellerDescription(res.data?.seller?.sellerDescription)
    }).catch(err => {
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller])
  const percentage = (value) => {
    return 20 * value / 100;
  }
  const fun = (id) => {
    for (let i = 0; i < cartItem.length; i++) {
      if (id === cartItem[i].item.id) {
        return true;
      }
    }
    return false;
  }
  const isInFav = (id) => {
    for (let i = 0; i < favourites.length; i++) {
      if (id === favourites[i].products) {
        return true;
      }
    }
    return false;
  }
  const mystyle = {
    verticalAlign: "middle",
    fontSize: "1.5rem",
    border: "2px solid #fff",
    padding: "6px",
    borderRadius: "50%",
    color: "#E86669",

  };
  const mystyle1 = {
    verticalAlign: "middle",
    fontSize: "1.5rem",
    border: "2px solid #fff",
    padding: "6px",
    borderRadius: "50%",
    color: "#fff",

  };
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % productList?.filter(elem => seller === elem?.seller_name).length;
    setItemOffset(newOffset);
  };
  const HandleAddToFavourite = async (id) => {
    setLoader(id)
    try {
      const response = await axiosPrivate.post(`product/add_to_favourite/${id}/`, {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.get('access')}`
          },
          withCredentials: true
        }
      );
      if (response) {
        setIsFavourite(prev => !prev)
        setLoader("")
      }
      //clear state and controlled inputs
      //need value attrib on inputs for this
    } catch (err) {
      // setText(err.code)
      setLoader("")
    }
  }
  const HandleRemoveFromFavourite = async (id) => {
    setLoader(id)
    let favId;
    for (let i = 0; i < favourites.length; i++) {
      if (id === favourites[i].products) {
        favId = favourites[i].id;
      }
    }
    try {
      const response = await axiosPrivate.delete(`product/remove_from_favourite/${favId}/`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.get('access')}`
          },
          withCredentials: true
        }
      );
      if (response) {
        setIsFavourite(prev => !prev)
        setLoader("")
      }
    } catch (err) {
      // setText(err.code)
      setLoader("")
    }
  }
  const AddToCart = async (id) => {
    setLoader(id)
    try {
      const response = await axiosPrivate.post(`/product/add_to_cart/${id}/`, {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.get('access')}`
          },
          withCredentials: true
        }
      );
      if (response) {
        setCheckCart(!checkCart)
        setCartBool(prev => !prev)
        setLoader("")
      }
    } catch (err) {
      // setText(err.code)
      setLoader("")
    }
  }
  const filterData = currentItems?.map((g, index) => {
    if (seller === g.seller_name) {
      return (
        <><Carts
          key={index}
          {...g}
          percentage={percentage}
          fun={fun}
          isInFav={isInFav}
          mystyle1={mystyle1}
          mystyle={mystyle}
          loader={loader}
          index={index}
          HandleRemoveFromFavourite={HandleRemoveFromFavourite}
          HandleAddToFavourite={HandleAddToFavourite}
          AddToCart={AddToCart}
        />
        </>
      )
    }
    return null;
  })

  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  }
  return (
    <>
      <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
      {showPage === true && <>
        <Header />
        <div className="container-fluid Seller-profile-head">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12 mt-lg-0 mt-md-0  mt-3 text-start">
              <div className="Seller-info-section py-5 px-lg-5 px-md-2 d-lg-flex d-md-flex justify-content-center">

                {/* <div className="col-lg-8  mx-auto">
                                <div className="row "> */}
                <div className="col-lg-4 col-md-4 col-12 d-flex justify-content-center">
                  <div>
                    <div className="">
                      <img src={image} alt="not found" style={{
                        width: "130px",
                        height: "130px",
                        borderRadius: "50%",
                        objectFit: 'cover',
                        objectPosition: 'center'
                      }}
                      />
                    </div>
                    <div className="mt-3 text-center">
                      <span style={{ color: "#E86669" }} className=" mail-size">{userName}{isVerified && <i className="far fa-check-circle mt-3"></i>}</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 col-md-8 col-12 text-lg-start text-md-start text-center mt-lg-0 mt-md-0 mt-3">
                  {/* <div className="d-flex mb-2">
                                        <i className="fa-solid fa-star mt-1"></i>
                                        <i className="fa-solid fa-star mt-1"></i>
                                        <i className="fa-solid fa-star mt-1"></i>
                                        <i className="fa-solid fa-star mt-1"></i>
                                        <i className="fa-solid fa-star mt-1"></i>
                                        <span className="ms-1">(22)</span>
                                    </div> */}
                  {sellerDescription && <div className="">
                    {sellerDescription.length > 600 ?
                      <>{!readMore && <span className="about-me pt-2" onClick={() => setReadMore(!readMore)}>{sellerDescription?.substring(0, 650)}... <span style={{ cursor: "pointer", color: "rgb(232, 102, 105)" }}>Read More</span></span>}
                        {readMore && <span className="about-me pt-2" onClick={() => setReadMore(!readMore)}>{sellerDescription} <span style={{ cursor: "pointer", color: "rgb(232, 102, 105)" }}>Read Less</span></span>}</>
                      : <span className="about-me pt-2">{sellerDescription}</span>}
                  </div>}
                </div>
                {/* </div>
                            </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 mt-lg-0 mt-md-0 mt-3 text-start ">
              <div className="Seller-info-section py-5 px-lg-5 px-md-2 d-flex justify-content-center">

                <div id="resp-table" className="p-2 px-3">
                  <div id="resp-table-body">
                    <div className="resp-table-row">
                      <div className="table-body-cell">
                        Age
                      </div>
                      <div className="table-body-cell">
                        : &nbsp;{calculate_age(dateOfBirth)}
                      </div>

                    </div>
                    <div className="resp-table-row">
                      <div className="table-body-cell">
                        Race
                      </div>
                      <div className="table-body-cell">
                        : &nbsp;{race}
                      </div>
                    </div>
                    <div className="resp-table-row">
                      <div className="table-body-cell">
                        Body Type
                      </div>
                      <div className="table-body-cell">
                        : &nbsp;{bodyType}
                      </div>
                    </div>
                    <div className="resp-table-row">
                      <div className="table-body-cell">
                        Gender
                      </div>
                      <div className="table-body-cell">
                        : &nbsp;{gender}
                      </div>
                    </div>
                    <div className="resp-table-row">
                      <div className="table-body-cell">
                        Country
                      </div>
                      <div className="table-body-cell">
                        : &nbsp;{country}
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <center><h1 className="mt-5" style={{ color: 'rgb(232, 102, 105)' }}>Available Products</h1></center>
          <div className="row mt-5">
            {filterData}
          </div>
        </div>
        {currentItems?.length > 0 && <div className="row"><div className="col-4"></div><div className="col-4">
          <ReactPaginate
            activeClassName="active"
            nextLinkClassName="page-link"
            nextClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            previousLabel="previous"
            nextLabel="next"
            className=""
            breakLabel="..."
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            forcePage={pageOffset}
          /></div><div className="col-4"></div></div>}
      </>
      }
    </>
  )
}
export default SellerProfilePage;