import { AutoComplete } from 'antd';
import React from 'react';
const OrderSearchBar = ({
    searchQuery,
    setSearchQuery,
}) => {
    const onChange = (data) => {
        setSearchQuery(data)
    };
    return (
        <>
            <label>Tracking number #</label>
            <AutoComplete
                style={{
                    width: "100%",
                    marginBottom: "1rem",
                    marginTop: "0.5rem"
                }}
                value={searchQuery}
                onChange={onChange}
                placeholder="input here"
            />
            <br />
        </>
    );
};
export default OrderSearchBar;