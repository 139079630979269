import React from 'react'
import AboutUs from './subcomponents/AboutUs/AboutUs'
import Benefits from './subcomponents/Benefits/Benefits'
import OurGuarantee from './subcomponents/OurGuarantee/OurGuarantee'
import Header from '../../../layout/Header/Header'
import Footer from '../../../layout/Footer/Footer'
import './About.css'
const About = () => {
    return (
        <>
            <Header />
            <AboutUs />
            <Benefits />
            <OurGuarantee />
            <Footer />
        </>
    )
}

export default About