export default{
  age:[
      {
        label:"AGE",
        value:"",
      },
      {
        label:"21-25",
        value:"21-25",
      },
      {
        label:"26-30",
        value:"26-30",
      },
      {
        label:"31-35",
        value:"31-35",
      },
      {
        label:"36-40",
        value:"36-40",
      },
      {
        label:"41-45",
        value:"41-45",
      },
      {
        label:"46-50",
        value:"46-50",
      },
      {
        label:"50+",
        value:"50+",
      },
    ],
    size:[
      {
        label:"BODY TYPE",
        value:"",
      },
      {
        label:"SKINNY",
        value:"SKINNY",
      },
      {
        label:"AVERAGE",
        value:"AVERAGE",
      },
      {
        label:"CURVY",
        value:"CURVY",
      },
      {
        label:"ATHLETIC",
        value:"ATHLETIC",
      },
    ],
    country:[
      {
        label:"COUNTRY",
        value:"",
      },
      {
        label:"Pakistan",
        value:"1",
      },
      {
        label:"Columbia",
        value:"2",
      },
      {
        label:"Ireland",
        value:"3",
      },
    ],
    race:[
      {
        label:"RACE",
        value:"",
      },
      {
        label:"Chinese",
        value:"CHINESE",
      },
      {
        label:"Taiwanese",
        value:"TAIWANESE",
      },
      {
        label:"Japanese",
        value:"JAPANESE",
      },
      {
        label:"Korean",
        value:"KOREAN",
      },
      {
        label:"Thailand",
        value:"THAILAND",
      },
      {
        label:"Malay",
        value:"MALAY",
      },
      {
        label:"Indian",
        value:"INDIAN",
      },
      {
        label:"Caucasian",
        value:"CAUCASIAN",
      },
      {
        label:"Eurasian",
        value:"EURASIAN",
      },
      {
        label:"Russian",
        value:"RUSSIAN",
      },
      {
        label:"Mixed",
        value:"MIXED",
      },
      {
        label:"Others",
        value:"OTHERS"
      }
    ],
}
