import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./ArticleDescription.css";

const ArticleDescription = ({ article }) => {
    return (
        <>
            {/* <div
                className='text-center'
                style={{ backgroundColor: "white", color: 'black' }}
            >
                <div>
                    <img src={article?.cover}
                        alt="not found"
                        width={"100%"} height={"50vh"} />
                </div>

                <div dangerouslySetInnerHTML={{ __html: article?.content }} />
            </div> */}

            <section id='view-article'>
                {/* <div className="view-article-hero" style={{ background: `url(${article?.cover}) no-repeat`, backgroundSize: 'cover' }}> */}
                <div className="view-article-hero" style={{ background: `linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5)), url(${article?.cover}) no-repeat center/cover` }}>
                    {/* <div className="view-article-hero" style={{ backgroundImage: `url(${article?.cover})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}> */}
                    <div className="view-article-hero-container" data-aos="zoom-in" data-aos-delay="100">
                        <div className="row container-fluid">
                            <div className="col-lg-12 text-left py-5 px-5">
                                <h3 className="pb-0 pe-5">Best Underwear Designs to get According to your Astrology Sign</h3>
                                <div className='date'>
                                    <p className='mb-0'>SEP</p>
                                    <p className='mb-0'>7</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row px-5 mx-3'>
                    <nav className='my-5' aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item text-dark opacity-5"><Link to="/">ARTICLES</Link></li>
                            <li className="breadcrumb-item text-dark active" aria-current="page">Best Underwear Designs to get According to your Astrology Sign</li>
                        </ol>
                    </nav>
                    <div dangerouslySetInnerHTML={{ __html: article?.content }} />
                </div>

            </section >
        </>
    )
}

export default ArticleDescription