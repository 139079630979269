import { Button, Dropdown, Space, } from 'antd';
import './Dropdown.css';
import React from 'react';
const CustomDropdown = ({ searchType, setSearchType }) => {
    const handleMenuClick = (e) => {
        if(e?.key==='1'){
            setSearchType(true)
        }else{
            setSearchType(false)
        }
    };
    const items = [
        {
            label: 'Products',
            key: '1',
        },
        {
            label: 'Sellers',
            key: '2',
        },
    ];
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };
    return (
        <Dropdown menu={menuProps} className='search-btn'>
            <Button >
                <Space>
                    {searchType ? "Products" : "Sellers"}
                </Space>
            </Button>
        </Dropdown>
    );
}
export default CustomDropdown;