import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "../../../../../services/api/axios";
import OtpInput from 'react-otp-input';
import Timer from "../Timer/Timer";
import logo from '../../../../../assets/images/png/Fantasy_Senses_logo.png';
import './Mainotp.css';
import { useTranslation } from "react-i18next";
const cookies = new Cookies();
export default function Mainotp() {
    const { t } = useTranslation('registration');
    const [validOtp, setValidOtp] = useState(cookies.get('Otp'));
    const [phone] = useState(cookies.get('phoneNo'));
    const [newOtp, setNewOtp] = useState('');
    const [reset, setReset] = useState(false);
    const Navigate = useNavigate();
    const errMsg = "OTP is not valid";
    const [showErr, setShowErr] = useState(false);
    const [otp, setOtp] = useState('');
    const Handleotp = () => {
        if (otp == validOtp || otp == newOtp) {
            Navigate('/user-details')
        }
        else {
            setShowErr(true)

        }
    }
    const GetOtp = () => {
        axios.post("user/send_otp/", { phoneNo: phone },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            setNewOtp(res.data?.otp)
            setReset(true)
            setShowErr(false)
        }).catch(err => {
        })
    }
    return (
        <>
            <section id="otp">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card shadow text-white" style={{ borderradius: "0.5rem", height: "45rem" }}>
                                <div className="card-body p-lg-5 p-md-5 p-4">

                                    <div className="mb-md-5">
                                        <div className='text-center mb-3'>
                                            <img src={logo} className="img-fluid" alt="Fantasy Senses" />
                                        </div>
                                        {/* <h2 className="fw-bold mb-2 text-uppercase mb-5" style={{ color: "#f6ecd0" }}>OTP</h2> */}
                                        <p className="text-center mb-0">{t('p3')}</p>
                                        <p className="text-center mb-5">{t('text16')}</p>
                                        {showErr && <><p className="p-4"
                                            style={{ color: "#664d03", backgroundColor: "#fffecd", borderColor: "#ffecb5", borderRadius: "0.25rem" }}>
                                            {errMsg}.
                                        </p>
                                        </>}
                                        <div className="form-outline form-white  mb-4">

                                            {/* <h5 className="text-center mt-3 mb-5">Enter verification code</h5> */}

                                            <div className="d-flex justify-content-center pt-5" style={{ color: "white" }}>
                                                <OtpInput
                                                    className="otp-fields"
                                                    value={otp}
                                                    // placeholder="------"
                                                    onChange={setOtp}
                                                    numInputs={6}
                                                    separator={<span>-</span>}
                                                    renderInput={(props) => <input {...props} />}
                                                    inputStyle={{ color: "white" }}
                                                    style={{
                                                        width: '4em', textAlign: 'center', borderBottom: '2px solid gray', background: 'black',
                                                        borderTop: '0', borderLeft: '0', borderRight: '0',
                                                        marginLeft: '5px',
                                                        marginRight: '5px',
                                                        color: "white"
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <Timer
                                            reset={reset}
                                            setReset={setReset}
                                            setValidOtp={setValidOtp}
                                            getOtp={GetOtp} />
                                        <div className="text-center mb-5 position">
                                            <button onClick={() => Handleotp()} className="Otpbtn mb-3" type="button">{t('btn1')}</button>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}