import React, { useContext, useEffect, useRef } from 'react'
import Hero from './subcomponents/Hero/Hero'
import HowItWorksForSellers from './subcomponents/HowItWorksForSellers/HowItWorksForSellers'
import HowItWorksForBuyers from './subcomponents/HowItWorksForBuyers/HowItWorksForBuyers'
import FeaturedSellers from './subcomponents/FeaturedSellers/FeaturedSellers'
import FeaturedProducts from './subcomponents/FeaturedProducts/FeaturedProducts'
import Articles from './subcomponents/Articles/Articles'
import Header from '../../../layout/Header/Header'
import Footer from '../../../layout/Footer/Footer'
import { GlobalContext } from '../../../store/GlobalContext'
const LandingPage = () => {
    const { state, dispatch } = useContext(GlobalContext)

    useEffect(() => {
        if (state.setContactRef && state.contactRef && state.contactRef.current) {
            setTimeout(() => {
                if (state.contactRef)
                    state.contactRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                dispatch({
                    type: 'SET_CONTACT_REF',
                    payload: false
                });
            }, 500); 
        }

    }, [state.setContactRef, state.contactRef])
    return (
        <main>
            <Header />
            <Hero />
            <HowItWorksForSellers />
            <HowItWorksForBuyers />
            <FeaturedSellers />
            <FeaturedProducts />
            <Articles />
            <Footer />
        </main>
    )
}

export default LandingPage