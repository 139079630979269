import Footer from "../../../layout/Footer/Footer";
import Header from "../../../layout/Header/Header";
import UserProfile from "./subcomponents/UserProfile";

const Profile = () => {
    return (
        <>
            <Header />
            <UserProfile />
            <Footer />
        </>
    )
}

export default Profile;