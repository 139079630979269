import React, { useContext } from 'react'
import { GlobalContext } from '../../../../store/GlobalContext'

const Summary = () => {
    const { state } = useContext(GlobalContext);
    return (
        <div className='card'>
            <h4 className='mb-3 mt-2 ps-lg-2'>Order Summary</h4>
            <table className="table table-borderless table-equal-columns">
                <tr>
                    <td>Total Payment</td>
                    <td>S${state.checkoutInfo?.price?.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Shipping fee</td>
                    <td>S${state.checkoutInfo?.shipping_price}</td>
                </tr>
                <tr>
                    <td>Transaction fee</td>
                    <td>S${state.checkoutInfo?.transactionFee.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Total Items</td>
                    <td>{state.checkoutInfo?.quantity}</td>
                </tr>
                <br />
                <tr>
                    <th>Total Amount</th>
                    <th>S${state.checkoutInfo?.orderTotal?.toFixed(2)}</th>
                </tr>
            </table>
        </div>
    )
}

export default Summary
