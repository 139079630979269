import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { extractMonthAndDay } from '../../../../../utils/helpers/helpers';
import { GlobalContext } from '../../../../../store/GlobalContext'
const Articles = () => {
    const { t } = useTranslation('landingpage');
    const { state } = useContext(GlobalContext)
    const [expanded, setExpanded] = useState(false);
    const maxLength = 80;
    const toggleDescription = () => {
        setExpanded(!expanded);
    };
    const Navigate = useNavigate();
    return (
        <section id='articles'>
            <div className="container-fluid mt-3" data-aos="fade-up">
                <div className='section-title text-center'>
                    <h2 className='mb-0'>{t('heading7')}</h2>
                </div>
                <div className='row px-lg-5 mx-3'>
                    {state.blogs?.map((elem) => (
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch" key={elem?.id} style={{ cursor: "pointer" }} onClick={() => Navigate(`/new/view-articles/${elem?.uuid}`)}>
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={elem?.thumbnail} className="img-fluid image-item" alt="" />
                                    <div className='date'>
                                        <p className='mb-0'>{extractMonthAndDay(elem?.created_at)?.month}</p>
                                        <p className='mb-0'>{extractMonthAndDay(elem?.created_at)?.day}</p>
                                    </div>
                                </div>
                                <div className="member-info">
                                    <h5>{elem?.title}</h5>
                                    <div className="description">
                                        <p>{expanded ? elem?.description : elem?.description?.length > maxLength ? `${elem?.description.slice(0, maxLength)}... ` : elem?.description}
                                            {elem?.description?.length > maxLength && (
                                                <button className="toggle-btn" onClick={toggleDescription} style={{ background: "none", border: "none", color: "#F47274", padding: "0", width: "auto", fontSize: "1vw" }}>
                                                    {expanded ? 'Read less' : 'Read more'}
                                                </button>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='text-center my-4'>
                    <button className='see-more' onClick={() => Navigate('/new/articles')}>{t('text6')}</button>
                </div>
            </div>
        </section>
    )
}

export default Articles;