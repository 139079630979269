import React from 'react'
import Header from '../../../../layout/Header/Header';
import Footer from '../../../../layout/Footer/Footer';
import "./ThankYou.css"

const ThankYou = () => {
    return (
        <>
            <Header />
            <section id='thankyou'>
                <div className='thank-container'>
                    <div className='row px-5 mx-3'>
                        <h2>Thank your for your order!</h2>
                        <p>Your order has been placed! You’ll received an email for order notification!</p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ThankYou