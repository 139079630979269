import "./Header.css";
import React, { useState, useEffect, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import { useContext } from "react";
import logo from '../../assets/images/png/Fantasy_Senses_logo.png'
import { useTranslation } from 'react-i18next';
import { GlobalContext } from "../../store/GlobalContext";
import Cart from "../../components/Modal/Cart/Cart";
import { calculateTotalItemCount } from "../../utils/helpers/helpers";
import Slide from '@mui/material/Slide';
import FormControlLabel from '@mui/material/FormControlLabel';
import { RxHamburgerMenu } from "react-icons/rx";
// import Box from '@mui/material/Box';
import { Button, Group, Text, Collapse, Box } from '@mantine/core';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useDisclosure } from '@mantine/hooks';
import Cookies from 'universal-cookie'
const cookies = new Cookies();

export default function Header() {
  const [opened, { toggle: toggleOpened }] = useDisclosure(false);
  const [opened2, { toggle: toggleOpened2 }] = useDisclosure(false);
  const collapseRef = useRef(null);
  const [scrollToContactFlag, setScrollToContactFlag] = useState(false)
  const isLoggedIn = window.localStorage.getItem("isLoggedIn");
  const access = cookies.get('access');
  const { state, dispatch } = useContext(GlobalContext);
  const gender = state.productsApiParams.gender ?? "MALE"
  const location = useLocation()
  const pathname = location?.pathname;
  const { t, i18n } = useTranslation('header');
  const userName = state.user?.userName;
  const image = state.user?.image;
  const { setAuth } = useContext(AuthContext);
  const [hover, setHover] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [language, setLanguage] = useState(state.language);
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const logout = async () => {
    setAuth({});
    window.localStorage.setItem("isLoggedIn", "false");
    navigate('/login')
  };
  const hoverStyle = {
    height: '85px',
    color: `white`,
    borderRadius: "5px",
  };
  useEffect(() => {
    dispatch({
      type: "LANGUAGE",
      payload: language
    });
    i18n.changeLanguage(language);
  }, [language])
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [opened])
  console.log('OPENED---', opened)
  const handleClickOutside = (event) => {
    if (collapseRef.current && !collapseRef.current.contains(event.target) && opened) {
      collapseRef.current.style.display = 'none';
    }
  };
  function handleChangeBuyerSide(linkToNavigate) {
    dispatch({
      type: "BUYER_SIDE",
      payload: !state.buyerSide
    });
    navigate(linkToNavigate)
  }
  const handleChangeBuyerSide2 = () => {
    dispatch({
      type: "BUYER_SIDE",
      payload: !state.buyerSide
    });
  }

  const scrollToContact = () => {
    if (state.contactRef) {
      if (state.contactRef.current) {
        // navigate('/')
        // state.contactRef.current.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'start',
        // });
        dispatch({
          type: 'SET_CONTACT_REF',
          payload: true
        })
      }
    }
  };
  // const scrollToContact = (scrollToContact) => {
  //   if (scrollToContact && state.contactRef && state.contactRef.current) {
  //     state.contactRef.current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start',
  //     });
  //   }
  // };
  // useEffect(() => {
  //   if (scrollToContactFlag) {
  //     scrollToContact(true);
  //     setScrollToContactFlag(false); // Reset the flag after scrolling
  //   }
  // }, [scrollToContactFlag]);
  // const handleNavClick = () => {
  //   // Navigate to the homepage
  //   navigate('/')
  //   // Set the flag to indicate scrolling to contact after navigating
  //   setScrollToContactFlag(true);
  // };
  return (
    // <!-- ======= Header ======= -->
    window.screen.width > 500 ? (
      <header id="header" className="d-flex align-items-center ">
        <Cart showModel={showModel} setShowModel={setShowModel} />
        <div className="container-fluid d-flex align-items-center pe-0">
          <div id="logo" className="me-auto ps-lg-5 ps-lg-0">
            <Link to={isLoggedIn === "true" ? "/product_page_men" : "/"}>
              <img src={logo} alt="Fantasy Senses" />
            </Link>
          </div>
          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li>
                <Link
                  className={`nav-link scrollto ${pathname === '/' && 'active'}`}
                  id="landing-page-header"
                  to="/"
                >
                  {t('nav1')}
                </Link>
              </li>
              <li>
                <Link
                  className={`nav-link scrollto ${pathname === '/about' && 'active'}`}
                  id="landing-page-header"
                  to="/about"
                >
                  {t('nav2')}
                </Link>
              </li>
              <li>
                <Link
                  className={`nav-link scrollto ${(pathname === '/product_page_men' || pathname === '/product_page_women') && 'active'}`}
                  id="landing-page-header"
                  to="/product_page_men"
                  onClick={handleChangeBuyerSide2}
                >
                  {t('nav3')}
                </Link>
              </li>
              <li>
                <Link
                  className={`nav-link scrollto ${pathname === '/sell' && 'active'}`}
                  id="landing-page-header"
                  to="/dash"
                  onClick={handleChangeBuyerSide2}
                >
                  {t('nav4')}
                </Link>
              </li>
              <li
                onClick={() => {
                  dispatch({
                    type: 'SET_CONTACT_REF',
                    payload: true
                  })
                }}
              >
                <Link
                  className={`nav-link scrollto ${pathname === '/howitworks' && 'active'}`}
                  id="landing-page-header"
                  to={'/'}
                >
                  {t('nav5')}
                </Link>

              </li>
              <li>
                <Link
                  className={`nav-link scrollto ${pathname === '/faq' && 'active'}`}
                  id="landing-page-header"
                  to="/faq"
                >
                  {t('nav6')}
                </Link>
              </li>
              <li>
                {isLoggedIn === "true" && access ? (
                  <div className="btn-group col-lg-3 col-md-3">
                    <Dropdown id="header_dropdown" className="user_drop ms-lg-2 ms-2  order-lg-3 order-md-3 order-3">
                      <Dropdown.Toggle variant="" id="dropdown-basic">
                        {image && access ? (
                          <span className="buyer-profile-img">
                            <img src={image} alt="not found" style={{ width: "", height: "" }} />
                          </span>
                        ) : (
                          <i className="fa-solid fa--circle--user  ms-3"></i>
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="my_dropdown shadow p-4">
                        <Dropdown.Item
                          as={Link}
                          to="/Profile"
                          className="text-white mb-1"
                        >
                          {" "}
                          {userName}
                        </Dropdown.Item>
                        <hr className="my-2" />
                        {
                          state.buyerSide
                            ? <>
                              <Dropdown.Item
                                onClick={() => handleChangeBuyerSide("/Dash")}
                                className="btn btn-primary text-uppercase my-3"
                              >
                                {t('dropdown11')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={Link}
                                to="/Myorders"
                                className="myWishlist mb-1"
                              >
                                {t('dropdown12')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={Link}
                                to="/Wishlist"
                                className="myWishlist mb-1"
                              >
                                {t('dropdown13')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={Link}
                                to="/Profile"
                                className="Profile mb-1"
                              >
                                {t('dropdown14')}
                              </Dropdown.Item>
                            </>
                            : <>
                              <Dropdown.Item
                                onClick={() => handleChangeBuyerSide(gender === "MALE" ? "/product_page_men" : "/product_page_women")}
                                className="btn btn-primary text-uppercase my-3"
                              >
                                {t('dropdown27')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={Link}
                                to="/dash"
                                className="myWishlist mb-1"

                              >
                                {t('dropdown23')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={Link}
                                to="/products_list"
                                className="myWishlist mb-1"
                              >
                                {t('dropdown26')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={Link}
                                to="/orders_list"
                                className="myWishlist mb-1"
                              >
                                {t('dropdown12')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={Link}
                                to="/seller_sales"
                                className="Profile mb-1"
                              >
                                {t('dropdown24')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={Link}
                                to="/Profile"
                                className="Profile mb-1"
                              >
                                {t('dropdown25')}
                              </Dropdown.Item>
                            </>
                        }
                        <Dropdown.Item
                          onClick={() => logout()}
                          className="Sign_out "
                        >
                          {t('dropdown15')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <div className="btn-group btn-btn-group " role="group">
                    <div
                      to=""
                      type="button"
                      className="btn signIn-btn btn-sm d-flex"
                      onMouseOver={() => setHover(true)}
                      onMouseOut={() => setHover(false)}
                    >
                      <i className="fa-solid fa-circle-user me-2"></i>
                      <div>
                        <span onClick={() => navigate('/login')}>{t('dropdown16')}</span>
                        &nbsp;/&nbsp;
                        <span onClick={() => navigate('/signup')}>{t('dropdown17')}</span>
                      </div>
                    </div>
                  </div>
                )}
              </li>
              <li style={{ paddingRight: "0" }}>
                <div className="btn-group btn-btn-group" role="group">
                  <div
                    type="button"
                    className="btn align-items-center btn-sm"
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                    style={hover ? hoverStyle : { borderRadius: "0", color: "gray" }}
                  >
                    <div className="btn-group-vertical justify-content-center">
                      <div style={{ fontSize: "12px", padding: "0.5rem" }}>
                        {t('dropdown2title')}
                      </div>
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" style={{ backgroundColor: "black", borderColor: "black" }}>
                          {language === "en" ? t('dropdown21') : t('dropdown22')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="lang_dropdown" style={{ width: "2rem" }}>
                          <button className="dropdown-item" type="button" onClick={() => setLanguage("en")}>{t('dropdown21')}</button>
                          <button className="dropdown-item" type="button" onClick={() => setLanguage("zh")}>{t('dropdown22')}</button>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <Link
                    to=""
                    onClick={() => setShowModel(true)}
                    type="button"
                    className="btn align-items-center btn-sm"
                    onMouseOver={() => setHover1(true)}
                    onMouseOut={() => setHover1(false)}
                    style={hover1 ? hoverStyle : { height: "85px", color: "#fff", background: "#f47274", marginRight: "0", borderRadius: "0" }}
                  >
                    <span style={{ padding: "1rem" }}>
                      <div className="cart-icon-container">
                        <i className="fa-solid fa-cart-shopping text-white align-items-center ms-0" style={{ fontSize: "40px" }}></i>
                        <span className="customBadge" style={{ backgroundColor: hover1 ? "#f47274" : "black", color: hover1 ? "black" : "white" }}>{calculateTotalItemCount(state?.cartItems)}</span>
                      </div>
                    </span>
                  </Link>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    ) : (
      <>
        <nav className="nav__mobile d-flex justify-content-between position-fixed" style={{ backgroundColor: 'black', height: '55px', width: '100%', zIndex: 1, top: 0 }}>
          <Cart showModel={showModel} setShowModel={setShowModel} />

          <div id="logo" className="me-auto ps-lg-5 ps-lg-0 pl-3 d-flex justify-content-center align-items-center">
            <Link to={isLoggedIn === "true" ? "/product_page_men" : "/"}>
              <img src={logo} alt="Fantasy Senses" width={150} />
            </Link>
          </div>
          <div className="d-flex align-items-center justify-content-between " style={{ width: '23%' }}>
            <div style={{ padding: '10px' }}>
              <RxHamburgerMenu color="white" onClick={() => {
                toggleOpened(!opened);
              }} size={18} />

            </div>

            <Link
              to=""
              onClick={() => setShowModel(true)}
              type="button"
              className="btn align-items-center btn-sm"
              onMouseOver={() => setHover1(true)}
              onMouseOut={() => setHover1(false)}
              style={hover1 ? hoverStyle : { height: "55px", color: "#fff", background: "#f47274", marginRight: "0", borderRadius: "0", display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <span >
                <div className="cart-icon-container">
                  <i className="fa-solid fa-cart-shopping text-white align-items-center ms-0" style={{ fontSize: "25px" }}></i>
                  <span className="customBadge" style={{ backgroundColor: hover1 ? "#f47274" : "black", color: hover1 ? "black" : "white" }}>{calculateTotalItemCount(state?.cartItems)}</span>
                </div>
              </span>
            </Link>
          </div>

        </nav>
        <Collapse in={opened} ref={collapseRef} onF>
          <div className="nav__mobileCollapse">
            <ul>
              <li>
                {
                  isLoggedIn === "true" && access ? (
                    <>
                      <p onClick={toggleOpened2}
                        className={` scrollto ${pathname === '/' && 'active'}`}
                        id="landing-page-header"
                        to="/">
                        {userName}<MdOutlineKeyboardArrowDown />
                      </p>
                      <Collapse in={opened2}>
                        <ul>
                          {state.buyerSide ? (
                            <>
                              <li
                                onClick={() => handleChangeBuyerSide("/dash")}
                                className="btn btn-primary text-uppercase my-3"
                              >
                                {t('dropdown11')}
                              </li>
                              <li >
                                <Link
                                  as={Link}
                                  to="/Myorders"
                                  className="myWishlist mb-1"
                                >

                                  {t('dropdown12')}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  as={Link}
                                  to="/Wishlist"
                                  className="myWishlist mb-1"
                                >
                                  {t('dropdown13')}
                                </Link>

                              </li>
                              <li>
                                <Link
                                  as={Link}
                                  to="/Profile"
                                  className="Profile mb-1"
                                >
                                  {t('dropdown14')}
                                </Link>
                              </li>
                            </>
                          ) : (
                            <>
                              <li
                                onClick={() => handleChangeBuyerSide(gender === "MALE" ? "/product_page_men" : "/product_page_women")}
                                className="btn btn-primary text-uppercase my-3"
                              >
                                {t('dropdown27')}
                              </li>
                              <li>
                                <Link
                                  as={Link}
                                  to="/dash"
                                  className="myWishlist mb-1"
                                >
                                  {t('dropdown23')}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  as={Link}
                                  to="/products_list"
                                  className="myWishlist mb-1"
                                >
                                  {t('dropdown26')}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  as={Link}
                                  to="/orders_list"
                                  className="myWishlist mb-1"
                                >

                                  {t('dropdown12')}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  as={Link}
                                  to="/seller_sales"
                                  className="Profile mb-1"
                                >
                                  {t('dropdown24')}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  as={Link}
                                  to="/Profile"
                                  className="Profile mb-1"
                                >
                                  {t('dropdown25')}
                                </Link>

                              </li>
                            </>
                          )}
                          <li
                            onClick={() => logout()}
                            className="Sign_out "
                            style={{ border: 'none' }}
                          >
                            {t('dropdown15')}
                          </li>
                        </ul>
                      </Collapse>
                    </>
                  ) : (
                    <div className="btn-group btn-btn-group" role="group">
                      <div
                        to=""
                        type="button"
                        className={` scrollto ${pathname === '/' && 'active'}`}
                        id="landing-page-header"
                        onMouseOver={() => setHover(true)}
                        onMouseOut={() => setHover(false)}
                      >
                        <i className="fa-solid fa-circle-user me-2"></i>
                        <div>
                          <span onClick={() => navigate('/login')}>{t('dropdown16')}</span>
                          &nbsp;/&nbsp;
                          <span onClick={() => navigate('/signup')}>{t('dropdown17')}</span>
                        </div>
                      </div>
                    </div>
                  )
                }
              </li>
              <li>
                <Link
                  className={` scrollto ${pathname === '/' && 'active'}`}
                  id="landing-page-header"
                  to="/"
                >
                  {t('nav1')}
                </Link>
              </li>
              <li>
                <Link
                  className={` scrollto ${pathname === '/about' && 'active'}`}
                  id="landing-page-header"
                  to="/about"
                >
                  {t('nav2')}
                </Link>
              </li>
              <li>
                <Link
                  className={` scrollto ${(pathname === '/product_page_men' || pathname === '/product_page_women') && 'active'}`}
                  id="landing-page-header"
                  to="/product_page_men"
                  onClick={handleChangeBuyerSide2}

                >
                  {t('nav3')}
                </Link>
              </li>
              <li>
                <Link
                  className={` scrollto ${pathname === '/sell' && 'active'}`}
                  id="landing-page-header"
                  to="/dash"
                  onClick={handleChangeBuyerSide2}

                >
                  {t('nav4')}
                </Link>
              </li>
              <li
                onClick={() => {
                  dispatch({
                    type: 'SET_CONTACT_REF',
                    payload: true
                  })
                }}
              >
                <Link
                  className={` scrollto ${pathname === '/howitworks' && 'active'}`}
                  id="landing-page-header"
                  to={'/'}
                >
                  {t('nav5')}
                </Link>

              </li>
              <li>
                <Link
                  className={` scrollto ${pathname === '/faq' && 'active'}`}
                  id="landing-page-header"
                  to="/faq"
                >
                  {t('nav6')}
                </Link>
              </li>
            </ul>
          </div>
        </Collapse >
      </>
    )
  );
}
