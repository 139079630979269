import React from "react";
import ProductDetailsMain from "./subcomponents/ProductDetailsMain";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";

const WomenProductDetails = () => {
    return (
        <div>
            <Header />
            <ProductDetailsMain />
            <Footer />
        </div>
    )
}

export default WomenProductDetails