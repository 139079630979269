import Cookies from "universal-cookie";
import axios from "../api/axios";
const cookies = new Cookies();
const fetchCartItems = async (language) => {
    try {
        const response = await axios.get('buyer/cart-items/dropdown/',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`,
                    'Accept-Language': language
                },
                withCredentials: true
            }
        ); // replace with your actual API endpoint
        const data = await response?.data;
        return data;
    } catch (error) {
        console.error('Error fetching products:', error.message);
    }
};

const fetchProducts = async (language, params) => {
    const { searchString, limit, offset, filterType, gender } = params;
    try {
        const response = await axios.get(`/product_list/?search_string=${searchString}&limit=${limit}&offset=${offset}&filter_type=${filterType}&gender=${gender}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`,
                    'Accept-Language': language
                },
                withCredentials: true
            }
        ); // replace with your actual API endpoint
        const data = await response?.data?.products;
        return data;
    } catch (error) {
        console.error('Error fetching products:', error.message);
    }
};

const fetchOrderItems = async (language, trackingId, startDate, endDate) => {
    try {
        const response = await axios.get(`product/orders_items_list/?tracking_number=${trackingId ? trackingId : ""}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`,
                    'Accept-Language': language
                },
                withCredentials: true
            }
        ); // replace with your actual API endpoint
        const data = await response?.data;
        return data;
    } catch (error) {
        console.error('Error fetching products:', error.message);
    }
};

const fetchFavorites = async (language) => {
    try {
        const response = await axios.get('product/favourites_list/',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`,
                    'Accept-Language': language
                },
                withCredentials: true
            });
        const data = await response?.data;
        return data;
    } catch (error) {
        console.error('Error fetching products:', error.message);
    }
};

const fetchUserInfo = async (language) => {
    try {
        const response = await axios.get('user/user_profile/',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`,
                    'Accept-Language': language
                },
                withCredentials: true
            });
        const data = await response?.data[0];
        return data;
    } catch (error) {
        console.error('Error fetching products:', error.message);
    }
}

const fetchCountryList = async (language) => {
    try {
        const response = await axios.get('product_country_list/',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`,
                    'Accept-Language': language
                },
                withCredentials: true
            });
        const data = await response?.data;
        return data;
    } catch (error) {
        console.error('Error fetching products:', error.message);
    }
}

const fetchCategoryList = async (language) => {
    try {
        const response = await axios.get('product_category_list/',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`,
                    'Accept-Language': language
                },
                withCredentials: true
            });
        const data = await response?.data;
        return data;
    } catch (error) {
        console.error('Error fetching products:', error.message);
    }
}

export { fetchCartItems, fetchFavorites, fetchUserInfo, fetchCountryList, fetchCategoryList, fetchOrderItems, fetchProducts };