export const USER_INFO = "USER_INFO";
export const BLOGS = "BLOGS";
export const LANGUAGE = "LANGUAGE";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_FEATURED_PRODUCTS = "SET_FEATURED_PRODUCTS";
export const SET_FEATURED_SELLERS = "SET_FEATURED_SELLERS";
export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const SET_FAVORITE_ITEMS = "SET_FAVORITE_ITEMS";
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const COUNTRY_LIST = "COUNTRY_LIST";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const ORDER_ITEMS = "ORDER_ITEMS"
export const REFRESH_PRODUCTS = "REFRESH_PRODUCTS";
export const REFRESH_ORDERS = "REFRESH_ORDERS";
export const REFRESH_PROFILE = "REFRESH_PROFILE";
export const REFRESH_FAVORITES = "REFRESH_FAVORITES";
export const REFRESH_CART = "REFRESH_CART";
export const SEARCH_TYPE = "SEARCH_TYPE";
export const SEARCH_QUERY = "SEARCH_QUERY";
export const PRODUCT_PAGE_OFFSET = "PRODUCT_PAGE_OFFSET";
export const CHECKOUT_INFO = "CHECKOUT_INFO";
export const ORDERS_API_PARAMS = "ORDERS_API_PARAMS";
export const PRODUCTS_API_PARAMS = "PRODUCTS_API_PARAMS";
export const BUYER_SIDE = "BUYER_SIDE";
export const CONTACT_REF = "CONTACT_REF";
export const SET_CONTACT_REF = 'SET_CONTACT_REF';