import Missing from '../../pages/public/Missing/Missing'
import Unauthorized from '../../pages/public/Unauthorized/unauthorized'
import Username from '../../pages/public/Username/Username'
import Otp from '../../pages/public/Otp/Otp'
import Main from '../../pages/public/Main/Main'
import Contact from '../../pages/public/LandingPage/subcomponents/Contact/Contact'
import About from '../../pages/public/About/About'
import Ourguarantee from '../../pages/public/LandingPage/subcomponents/Ourguarantee/Ourguarantee'
import Faq from '../../pages/public/Faq/Faq'
import Birthday from '../../pages/public/Birthday/Birthday'
import LandingPage from '../../pages/public/LandingPage/LandingPage'
import SignupPage from '../../pages/public/Signup/SignupPage'
import TermsAndConditions from '../../pages/public/TermsAndConditions/TermsAndConditions'
import Login from '../../pages/public/Login/Login'
import ResetPassword from '../../pages/public/ResetPassword/ResetPassword'
const unProtectedRoutes = [
    {
        path: "/",
        element: <LandingPage />
    },
    {
        path: "/Login",
        element: <Login />
    },
    {
        path: "/Signup",
        element: <SignupPage />
    },
    {
        path: "/About",
        element: <About />
    },
    {
        path: "/Ourguarantee",
        element: <Ourguarantee />
    },
    {
        path: "/faq",
        element: <Faq />
    },
    {
        path: "/Contact",
        element: <Contact />
    },
    {
        path: "/Main",
        element: <Main />
    },
    {
        path: "/user/reset-password/:token",
        element: <ResetPassword />
    },
    {
        path: "/otp",
        element: <Otp />
    },
    {
        path: "/username",
        element: <Username />
    },
    {
        path: "/user-details",
        element: <Birthday />
    },
    {
        path: "/TermsAndConditions",
        element: <TermsAndConditions />
    },
    {
        path: "/unauthorized",
        element: <Unauthorized />
    },
    {
        path: "*",
        element: <Missing />
    },
    {
        path: "/Myorders",
        element: ""
    },
    {
        path: "/Trackorders",
        element: ""
    },
    {
        path: "/Wishlist",
        element: ""
    }
]
export default unProtectedRoutes;