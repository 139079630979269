import React from 'react'
import Mainotp from './subcomponents/Mainotp/Mainotp'

const Otp = () => {
    return (
        <>
            <Mainotp />
        </>
    )
}

export default Otp