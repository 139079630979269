import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "../Dashboard.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import CustomSearchComponent from "../../../../components/CustomSearch/CustomSearchComponent";
import CustomDropdown from "../../../../components/Dropdowns/Dropdown";
const DashboardHeader = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const isLoggedIn = window.localStorage.getItem("isLoggedIn");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchType, setSearchType] = useState(true);
    const logout = async () => {
        setAuth({});
        window.localStorage.setItem("isLoggedIn", "false");
    };
    const handleKeyDown = (event) => {
        if (event.key === "Enter" && searchQuery !== "") {
            console.log("Enter key pressed!", searchQuery);
            navigate(`/searchpage/${searchQuery}`);
            // Call your function here
            // ...
        }
    };
    return (
        <div>
            <div className="container mt-5">
                {/* <div className="col-lg-12 mt-5"> */}
                <div className="row justify-content-between align-items-center" id="db-header">
                    {/* <div className="d-flex justify-content-between"> */}

                    <div id="logo" className="me-auto col-lg-4 col-md-4 col-6">
                        <Link to="/">
                            <img src="images/Fantasy_Senses_logo.png" alt="Fantasy Senses" />
                        </Link>
                        <Link to="index.html" className="scrollto"></Link>
                    </div>
                    <div className="col-lg-5 col-md-4 mt-2  offset-lg-2 offset-md-2 offset-1 d-lg-block d-md-block d-none order-lg-1 order-md-2 order-2 seller-search position-relative">
                        {/* <i className="fa fa-search"></i>
                                    <input type="text" className="form-control" placeholder="Search..." /> */}
                        <div onKeyDown={handleKeyDown} className="position-relative">
                            <CustomSearchComponent
                                searchQuery={searchQuery}
                                setSearchQuery={setSearchQuery}
                                searchType={searchType}
                            />
                            <div className="searchbar-drop">
                                <CustomDropdown
                                    searchType={searchType}
                                    setSearchType={setSearchType}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-1 col-md-1 col-2 me-lg-auto me-md-auto order-lg-2 order-md-2 order-1">
                        <Button variant="primary" onClick={handleShow} className="mt-2">
                            <i className="fa-solid fa-bars"></i>
                        </Button>
                    </div>

                    {/* </div> */}
                </div>
                {/* </div> */}
            </div>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Body>
                    <div className=" navbar-brand  mt-2  ">
                        <div className=" font-weight-bold ms-3 py-3" id="db-header">
                            <Link to={isLoggedIn === "true" ? "/product_page_men" : "/"} id="logo">
                                <img src="../images/Fantasy_Senses_logo.png" alt="Fantasy Senses" />
                            </Link>
                        </div>
                    </div>
                    <hr />
                    <ul className="navbar-nav d-flex flex-column   w-100">
                        <li className="nav-item w-100" activeclassname="active">
                            <NavLink to="/Dash" className="nav-link text-light Side_nav">
                                <i className="bi bi-speedometer2 dash-icon me-2"></i> Dashboard
                            </NavLink>
                        </li>

                        <li className="nav-item w-100 mt-2" activeclassname="active">
                            <NavLink to="/SellerPro" className="nav-link text-light Side_nav">
                                <i className="bi bi-bag-fill dash-icon me-2"></i> Products
                            </NavLink>
                        </li>
                        <li className="nav-item w-100 mt-2" activeclassname="active">
                            <NavLink
                                to="/Sellerorder"
                                className="nav-link text-light Side_nav"
                            >
                                <i className="bi bi-cart4 dash-icon me-2"></i>Orders
                            </NavLink>
                        </li>
                        <li className="nav-item w-100 mt-2" activeclassname="active">
                            <NavLink
                                to="/SellerInvoice"
                                className="nav-link text-light Side_nav"
                            >
                                <i className="bi bi-receipt dash-icon me-2"></i>Sales
                            </NavLink>
                        </li>
                        <li className="nav-item w-100 mt-2" activeclassname="active">
                            <NavLink
                                to="/Sellerabout"
                                className="nav-link text-light Side_nav"
                            >
                                <i className="bi bi-person-circle dash-icon  me-2"></i> About me
                            </NavLink>
                        </li>
                        <li
                            className="nav-item w-100 mt-2 primary"
                            style={{ background: "black" }}
                        >
                            <NavLink
                                id="SignOut"
                                to
                                onClick={logout}
                                className="nav-link text-light Side_nav"
                            >
                                <i className="fa fa-sign-out dash-icon  me-2"></i> Sign Out
                            </NavLink>
                        </li>

                        <hr />

                        <div className=" text-center Switch-buyer p-4 mt-3 col-9 mx-auto ">
                            {/* <div className=""> */}
                            <Link to={"/product_page_women"}>
                                <i className="fa-solid fa-chevron-right dashboard-switch-btn"></i>
                            </Link>
                            {/* </div> */}

                            <div className="mt-3">
                                <Link to={""} className="btn btn-primary text-uppercase my-3">
                                    Switch to Buyer
                                </Link>
                            </div>
                        </div>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};
export default DashboardHeader;
