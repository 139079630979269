import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from '../../services/api/axios';
import Cookies from 'universal-cookie';
import { GlobalContext } from '../../store/GlobalContext';
// import Dropdown from "react-bootstrap/Dropdown";
import { Button, Dropdown } from 'antd';
import CustomDropdown from '../Dropdowns/Dropdown';

const cookies = new Cookies();
const SearchNavbar = () => {
    const { state } = useContext(GlobalContext);
    const [searchType, setSearchType] = useState(true)
    const [suggestions, setSuggestions] = useState([]);
    const [searchFocus, setSearchFocus] = useState(false)
    const [query, setQuery] = useState("");
    const Navigate = useNavigate();
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setQuery(inputValue);
    };
    
    useEffect(() => {
        if (query) {
            axios.post("buyer/suggestion/", { filter_type: searchType ? "product" : "seller", search_string: query }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`,
                    'Accept-Language': state?.language
                },
                withCredentials: true
            }).then(res => {
                setSuggestions(res?.data)
            }).catch(err => {
                console.log(err)
            })
        }
    }, [query, searchType])
    return (
        <div
            onFocus={() => setSearchFocus(true)}
            onBlur={() => setSearchFocus(false)}
            className='search'>
            <input
                type="text"
                className="search-input"
                placeholder="Search..."
                id='searchText'
                name="searchKeyword"
                value={query}
                onChange={handleInputChange} />
            <i className="fa fa-search"></i>
            {/* <input className="search-btn" type='button' value={searchType ? "Products" : "Sellers"} onClick={() => setSearchType(!searchType)} /> */}
           
            <CustomDropdown searchType={searchType} setSearchType={setSearchType}/>
            {suggestions.length > 0 && searchFocus && (
                <div className="suggestions-container p-2" >
                    <ul>
                        {suggestions.map((suggestion, index) => (
                            <li key={index} style={{ cursor: "pointer" }}
                                onMouseDown={(e) => {
                                    e.preventDefault(); // Prevents the input field from losing focus
                                    Navigate(suggestion?.name ? `/product_display_page_women/${suggestion?.url_link}` : `/seller/${suggestion?.userName}`);
                                }}
                            >{suggestion?.name ?? suggestion?.userName}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>

    )
}

export default SearchNavbar
