import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import headerTranslations from './assets/translations/Layout/Header/header.json';
import footerTranslations from './assets/translations/Layout/Footer/footer.json';
import landingpage from './assets/translations/LandingPage/landingpage.json';
import registration from './assets/translations/Registration/registration.json';
import articles from './assets/translations/Articles/articles.json';
import addreview from './assets/translations/AddReviewPopup/add_review_popup.json';
import about from './assets/translations/About/about.json';
import addnewproduct from './assets/translations/AddNewProduct/add_new_product.json';
import buyerorderlist from './assets/translations/BuyerOrdersList/buyer_orders_list.json';
import receipt from './assets/translations/BuyerReceipt/receipt.json';
import wishlist from './assets/translations/BuyerWishlist/buyer_wishlist.json';
import checkout from './assets/translations/Checkout/checkout.json';
import faqs from './assets/translations/FAQs/faqs.json';
import payout from './assets/translations/Payout/payout.json';
import productdisplay from './assets/translations/ProductDisplay/productdisplay.json';
import product from './assets/translations/ProductsPage/productpage.json';
import profile from './assets/translations/SellerAndBuyerProfile/profile.json';
import sellerdashboard from './assets/translations/SellerDashboard/sellerdashboard.json';
import totalsales from './assets/translations/SellerDashboardTotalSales/totalsales.json';
import invoice from './assets/translations/SellerInvoice/sellerinvoice.json';
import sellerorderlist from './assets/translations/SellerOrdersList/seller_orders_list.json';
import sellerproductlist from './assets/translations/SellerProductsList/seller_products_list.json';
import sellerprofilereviews from './assets/translations/SellerProfileReviews/sellerprofilereviews.json';
import termsofservices from './assets/translations/TermsoFService/termsofservices.json';
import trackorder from './assets/translations/TrackOrder/track_order.json';
import sellerSales from './assets/translations/SellerDashboardTotalSales/totalsales.json'
import userprofile from './assets/translations/UserProfile/userprofile.json';
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                header: headerTranslations.en,
                footer: footerTranslations.en,
                landingpage: landingpage.en,
                registration: registration.en,
                articles: articles.en,
                addreview: addreview.en,
                about: about.en,
                addnewproduct: addnewproduct.en,
                buyerorderlist: buyerorderlist.en,
                receipt: receipt.en,
                wishlist: wishlist.en,
                checkout: checkout.en,
                faqs: faqs.en,
                payout: payout.en,
                productdisplay: productdisplay.en,
                product: product.en,
                profile: profile.en,
                sellerdashboard: sellerdashboard.en,
                totalsales: totalsales.en,
                invoice: invoice.en,
                sellerorderlist: sellerorderlist.en,
                sellerproductlist: sellerproductlist.en,
                sellerprofilereviews: sellerprofilereviews.en,
                termsofservices: termsofservices.en,
                trackorder: trackorder.en,
                sellerSales: sellerSales.en,
                userprofile: userprofile.en
            },
            zh: {
                header: headerTranslations.zh,
                footer: footerTranslations.zh,
                landingpage: landingpage.zh,
                registration: registration.zh,
                articles: articles.zh,
                addreview: addreview.zh,
                about: about.zh,
                addnewproduct: addnewproduct.zh,
                buyerorderlist: buyerorderlist.zh,
                receipt: receipt.zh,
                wishlist: wishlist.zh,
                checkout: checkout.zh,
                faqs: faqs.zh,
                payout: payout.zh,
                productdisplay: productdisplay.zh,
                product: product.zh,
                profile: profile.zh,
                sellerdashboard: sellerdashboard.zh,
                totalsales: totalsales.zh,
                invoice: invoice.zh,
                sellerorderlist: sellerorderlist.zh,
                sellerproductlist: sellerproductlist.zh,
                sellerprofilereviews: sellerprofilereviews.zh,
                termsofservices: termsofservices.zh,
                trackorder: trackorder.zh,
                sellerSales: sellerSales.zh,
                userprofile: userprofile.zh
            },
        },
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
