import React from 'react'
import Header from '../../../layout/Header/Header'
import Footer from '../../../layout/Footer/Footer';
const TermsAndConditions = () => {
    return (
        <>
            <Header />
            <div className="container terms">
                <h1>FANTASY SENSES TERMS & CONDITIONS </h1>
                <p>
                    These Terms and Conditions (referred to as "<strong>Terms</strong>")
                    of UB GENESIS Pte. Ltd. ("<strong>Fantasy Senses</strong>") govern
                    your entry and usage of our website. These Terms of Use are effective
                    immediately for users registering accounts or acknowledging acceptance
                    of these terms on or after the sign up process. In these Terms, the
                    words "we", "us" and "ours' ' refer to UB GENESIS Pte. Ltd. ("
                    <strong>Fantasy Senses</strong>") a registerd company located in
                    Singapore, which is collecting your personal data, or is operating the
                    website which you are accessing or using. By entering and using the
                    website or any of its services, functions or contents (including
                    transmitting, caching or storing of any such personal data), providing
                    us with your personal data, you agree to comply with these Terms. If
                    you do not agree to these Terms, you are not permitted to use the
                    Website and are kindly requested to refrain from doing so. The
                    registration as a user requires your express acceptance of these User
                    Terms.
                </p>
                <h4>SECTION 1 - ONLINE STORE AGREEMENT TERMS</h4>
                <p>
                    By agreeing to these Terms, you represent that you are at least the
                    age of 21 and you have given us your consent to use this site. You may
                    not use any of our photos found in Fantasy Senses and products for any
                    illegal or unauthorized purpose nor may you, in the use of the
                    Service, violate any laws in your jurisdiction (including but not
                    limited to copyright laws). A breach or violation of any of the Terms
                    will result in an immediate termination of your Account and Services.
                </p>
                <h4>SECTION 2 - PRODUCTS OR SERVICES</h4>
                <p>
                    We reserve the right to limit the sales of our products or services to
                    any person, geographic region or jurisdiction. This right will be
                    reviewed on a case-by-case basis. We reserve the right to refuse any
                    products you place with us. We may, in our sole discretion, limit or
                    cancel quantities purchased per person or per order. Products sold are
                    subject to no return and no exchange. We will ensure the product is of
                    the best quality when shipping out.
                </p>
                <h4>SECTION 3 - VERIFIED SELLERS & PRODUCTS</h4>
                <p>
                    Verified sellers are sellers that have verified themselves with us and
                    are deemed as reliable and legitimate. Products purchased under the
                    non-verified category are subject to risks and we are not liable for
                    any damage caused and/or fault products. Fantasy senses will do our
                    utmost in ensuring all sellers and products are legitimate and ensure
                    that products are of top quality.
                </p>
                <h4>SECTION 4 - PRIVACY</h4>
                <p>
                    We respect the privacy rights of each and everyone of our customers.
                    No private information will be disclosed to any unrelated entities.
                    All information provided to us will strictly be kept confidential for
                    company purposes.
                </p>
                <h4>SECTION 5 - ERRORS, INACCURACIES AND OMISSIONS</h4>
                <p>
                    There may be information on our website that contains typographical
                    errors, inaccuracies or omissions regarding the product descriptions,
                    pricing, promotions, offers, product shipping charges and
                    availability. We reserve the right to correct any errors, inaccuracies
                    or omissions, and to change or update information or cancel orders if
                    any information in the website is inaccurate at any time without prior
                    notice (including after you have submitted your order). You expressly
                    agree to not hold us responsible for any such omissions, inaccuracies
                    and typographical errors on the details of our products or any other
                    content on our website, whether or not the information were adapted
                    from our sellers.
                </p>
                <h4>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</h4>
                <p>
                    You agree to provide valid and complete account information for all
                    product purchases made at our store. This is to ensure that we can
                    complete your transactions and contact you as needed. We ensure that
                    all transactions done with us using credit cards will be billed under
                    the name UB GENESIS Pte. Ltd.
                </p>
                <h4>SECTION 7 - CHANGES TO TERMS & CONDITIONS</h4>
                <p>
                    We reserve the right, at our sole discretion, to update, change or
                    replace any part of these Terms & Conditions by posting updates and
                    changes to our website. It is your responsibility to constantly check
                    our website for changes. Your continual access to our website or the
                    service following the posting of any changes to these Terms
                    constitutes acceptance of those changes. Review the most current
                    version of the Terms & Conditions at any time at this page.
                </p>
                <h4>SECTION 8 - GOVERNING LAW</h4>
                <p>
                    These Terms and any separate agreements whereby we provide you
                    services shall be governed by and construed in accordance with the
                    laws of Singapore.
                </p>
                <h4>SECTION 9 - LIMITATION OF LIABILITY</h4>
                <p>
                    You expressly agree that your use of the service is at your sole risk.
                    The service and products delivered to you through the service are
                    (except as expressly stated by us) provided 'as is' and 'as available'
                    for your use, without any representation or conditions of any kind,
                    either express or implied, including all conditions of
                    merchantability, merchantable quality, fitness for a particular
                    purpose, durability, title, and non-infringement. Fantasy Senses
                    directors, officers, employees, affiliates, agents, interns,
                    suppliers, or service providers are not liable for any injury, loss,
                    claim, or any direct, indirect, incidental, punitive, special, or
                    consequential damages of any kind, including, without limitation lost
                    profits, lost revenue, lost savings, loss of data, replacement costs,
                    or any similar damages, whether based in contract, tort (including
                    negligence), strict liability or otherwise, arising from your use of
                    any of the service or any products procured using the service, or for
                    any other claim related in any way to your use of the service or any
                    product, including, but not limited to, any errors or omissions in any
                    content, or any loss or damage of any kind incurred as a result of the
                    use of the service or any content posted, transmitted, or otherwise
                    made available via the service, even if advised of their possibility.
                </p>

                <h1>FANTASY SENSES PRIVACY POLICY</h1>
                <p>
                    This Privacy Policy (referred to as "<strong>Policy</strong>")
                    describes how UB GENESIS PTE LTD ("<strong>Fantasy Senses</strong>")
                    collects, uses, and disclose your Personal Information when accessing
                    the website.{" "}
                </p>
                <h4>1. What Personal Data is Collected</h4>
                <p className="mb-3">
                    1.1 When using our website, any enquiry services, sales of products
                    provided or in your interaction with us, the personal data we may
                    collect includes:
                </p>
                <p>
                    a) <strong>Data Collected Directly</strong>. This may be done through
                    the website, over the phone, by email, or in person when you meet our
                    staff or representatives. The data collected may include, but is not
                    limited to:
                    <ul>
                        <li>
                            your contact information, including your first name, (billing
                            and/or delivery) address, date of birth, email address and phone
                            number. We need your valid email address and phone number to
                            contact you if we have any questions or information regarding your
                            order or the service that we are providing or will provide to you;
                        </li>
                        <li>
                            other information required to be able to process your order or to
                            provide you with the service, such as information concerning the
                            products you have ordered or the service we are providing to you,
                            the billing and/or delivery address, banking and/or credit card
                            details;
                        </li>
                        <li>
                            if you have created an online profile or account, the preferences
                            and interests stored in such profile or account; and
                        </li>
                        <li>
                            if you have contacted customer service, the details of this
                            contact and the contact history.{" "}
                        </li>
                    </ul>
                </p>
                <p>
                    (b) <strong>Data Collected by Automated Means</strong>. Various
                    technologies may be used on our website in order to make them more
                    user-friendly, effective and secure. Such technologies may lead to
                    data being collected automatically by us or by third parties on behalf
                    of us. This data does not generally, but may contain users’ personal
                    data. Examples of such technologies include:
                </p>
                <p>
                    <strong className="me-2" style={{ fontSize: "1.2rem" }}>
                        &#x2022;
                    </strong>
                    <strong>Click-Stream Data.</strong> A visit to our website results in
                    data that is transmitted from your browser to our server being
                    automatically collected and stored by us or by third parties on behalf
                    of us. This data can include, in particular, the following:
                    <ul className="click-stream">
                        <li>the visitor's IP address</li>
                        <li>the date and time of the visit</li>
                        <li>the referral URL (the site from which the visitor has come)</li>
                        <li>the pages visited on our website</li>
                        <li>
                            information about the browser used (browser type and version,
                            operating system, etc).
                        </li>
                    </ul>
                </p>
                <p>
                    <strong className="me-2" style={{ fontSize: "1.2rem" }}>
                        &#x2022;
                    </strong>
                    <strong>Cookies.</strong> A number of places on our website make use
                    of cookies. These are essentially small text files that are stored on
                    your computer's hard drive or your user device by your web browser. We
                    can use cookies to identify the owner of a user account and to store
                    articles in a shopping basket during the purchasing process. In other
                    words, cookies help to make our website more user-friendly, more
                    effective and more secure. The cookies usually used by us are
                    so-called "session cookies", which are automatically deleted at the
                    end of the visit to the relevant website. By default, web browsers
                    enable the use of cookies but this function can also be disabled.
                    However, this will result in some services not being available. It is
                    also possible to manually delete cookies after their use via the web
                    browser. More information may be available from your web browser
                    provider.
                </p>
                <p>
                    <strong className="me-2" style={{ fontSize: "1.2rem" }}>
                        &#x2022;
                    </strong>
                    <strong>Flash Cookies.</strong>A particular form of cookie is the
                    flash cookie. In contrast to normal cookies, these cookies are not
                    created and saved by the web browser but are governed by the Adobe
                    Flash plug-in. These can contain more information than normal cookies
                    and cannot be deleted or disabled via the browser; this is only
                    possible if you follow instructions available from the Adobe Flash
                    Player website. You can also obtain more information about these
                    cookies on that website.
                </p>
                <p>
                    <strong className="me-2" style={{ fontSize: "1.2rem" }}>
                        &#x2022;
                    </strong>
                    <strong>Web Beacons and Tracking Links.</strong>Web beacons (also
                    known as pixel tags and clear GIFs) involve graphics that are not
                    apparent to the user. Tracking links and/or similar technologies
                    consist of a few lines of programming code and can be embedded in our
                    website. In conjunction with cookies, these are primarily used for
                    statistical analysis purposes. This technology can also be used for
                    tracking traffic patterns on websites, as well as finding out if an
                    email has been received and opened and to see if there has been any
                    response.
                </p>
                <p>
                    <strong className="me-2" style={{ fontSize: "1.2rem" }}>
                        &#x2022;
                    </strong>
                    <strong>Web Analytics.</strong>Web analytics is the term given to a
                    method for collecting and assessing the behavior of visitors to
                    websites, including the analysis of traffic patterns in order to
                    determine the frequency of visits to certain parts of a website or to
                    find out what information and services our visitors are most
                    interested in. For these purposes, we primarily make use of
                    click-stream data and the other techniques listed above. Web analytics
                    are carried out by Google Analytics and/or other no selected parties.
                </p>
                <h4>2. How is the Collected Personal Data Used</h4>
                <p>
                    2.1 We use your personal data for the purposes of serving you. This
                    includes, where applicable:
                    <ul className="click-stream">
                        <li>
                            providing you with the products and services that you have
                            requested e.g. notifying you regarding the status of your order or
                            delivery, ensuring that your transactions on our websites are safe
                            and secure;
                        </li>
                        <li>providing you with a convenient shopping experience;</li>
                        <li>
                            contacting you for feedback after a sale of a product or service;
                            and
                        </li>
                        <li>
                            resolving any problems or disputes you may encounter in relation
                            to our products and services.
                        </li>
                    </ul>
                    <p className="mb-2">
                        2.2 We may also use your personal data for purposes connected or
                        relevant to the business of the UB GENESIS, such as:
                    </p>
                    <ul className="click-stream mt-0">
                        <li>
                            complying with legal and regulatory obligations and requirements;
                        </li>
                        <li>enforcing obligations owed to the UB GENESIS Pte. Ltd.</li>
                        <li>
                            researching and developing new products and services or improving
                            existing products and services of the UB GENESIS Pte. Ltd.
                        </li>
                        <li>
                            accounting, risk management, compliance and record keeping
                            purposes;
                        </li>
                        <li>
                            carrying out research, planning and statistical analysis; and
                        </li>
                    </ul>
                </p>
                <p>
                    2.3 Your personal data may also be anonymised for use by UB GENESIS
                    Pte. Ltd. for other purposes.
                </p>
                <p>
                    2.4 If you have consented, your personal data may be used by UB
                    GENESIS Pte. Ltd. for the purposes of marketing products and services
                    offered on the website or other products or services offered by UB
                    GENESIS Pte. Ltd. or those of its strategic partners or business
                    affiliates e.g. informing you of latest activities, special offers,
                    promotions or sending you newsletters.
                </p>
                <p>
                    2.5 In order for us to market products and services which are of
                    special interest and relevance to you, we may analyze and rely on your
                    overall interaction with us (such as but not limited to your shopping
                    behavior in e-shops, your ratings and reviews of products, your
                    contact history with our customer service, your newsletter clicks/
                    opening results, your surfing behavior (web tracking), the newsletter
                    types you are subscribed to, your participation in promotions or
                    events, your interactions with us.
                </p>
                <p>
                    2.6 If you have contacted us with any complaints, feedback, comments,
                    suggestions, we will use and disclose your personal data (including
                    your contact history) to respond to you and provide you with the best
                    service possible. If you are the complainant and you do not want us to
                    disclose your identity to the party you are complaining about, you
                    must let us know immediately. However, it may not always be possible
                    to handle your complaint on the basis of anonymity.
                </p>
                <p>
                    2.7 When using your personal data to contact you for the above
                    purposes, we may contact you via email, SMS, telephone or any other
                    electronic means.
                </p>
                <p>
                    2.8 If we need to use your personal data for any other purposes, we
                    will notify you and obtain your consent beforehand. You will be given
                    the opportunity to withhold or withdraw your consent for the use of
                    your personal data for these other purposes.
                </p>
                <h4>3. Disclosure of Information</h4>
                <p>3.1 We will not sell your personal data to third parties</p>
                <p>
                    3.2 We will only disclose your personal data to third parties where
                    you have provided us consent, and in the situations expressly set out
                    in this Policy. If you have consented to receiving marketing
                    information from us, we will be disclosing your personal data to our
                    business associates.
                </p>
                <p>
                    3.3 Your personal data may be disclosed and shared within UB GENESIS
                    Pte. Ltd. to allow us to provide the products and services which you
                    have requested.
                </p>
                <p>
                    3.4 We may disclose or share your personal data with third parties who
                    provide necessary services to us, such as:
                    <ul className="click-stream">
                        <li>
                            service providers and data processors working on our behalf and
                            providing services such as hosting and maintenance services,
                            analysis services, e-mail messaging services, delivery services,
                            handling of payment transactions, solvency check and address
                            check, etc; and
                        </li>
                        <li>
                            our consultants and professional advisers (such as accountants,
                            lawyers, auditors).
                        </li>
                    </ul>
                </p>
                <p>
                    3.5 We will also disclose your personal data to third parties in order
                    to comply with legal obligations or industry requirements. This
                    includes disclosures to legal, regulatory, governmental, tax and law
                    enforcement authorities.
                </p>
                <p>
                    3.6 Our website may have the functionalities to allow you to share
                    your personal data with other third parties such as other users of our
                    website. You are responsible for your choice(s) and are deemed to have
                    provided consent for any sharing of your personal data in the manner
                    provided by the website.
                </p>

                <p>
                    3.7 You fully understand and consent that we may transfer your
                    personal data to any location outside of Singapore for the purposes
                    set out in this paragraph 3. When transferring your personal data
                    outside of Singapore we will protect your personal data to a standard
                    comparable to the protection accorded to your personal data under the
                    Singapore Personal Data Protection Act 2012 by ensuring that the
                    recipient is either in a jurisdiction which has comparable data
                    protection laws, or is contractually bound to protect your personal
                    data.
                </p>

                <h4>4. Third Parties </h4>
                <p>
                    This Policy only applies to our website and information that we
                    collect from you. Our websites may contain links to other websites
                    which are not owned or maintained by us. When visiting these third
                    party websites or disclosing your personal data to third parties
                    (including buyers or sellers on our website), you should read their
                    privacy policies, or ask relevant questions before you disclose your
                    personal data. We are not responsible for the collection, use or
                    disclosure of your personal data by such third parties.
                </p>
                <h4>5. Social Networks</h4>
                <p>
                    5.1 Our website may provide you with social plug-ins from various
                    social networks (such as Facebook, Instagram and TikTok). If you
                    choose to interact with a social network, your activity on our website
                    will also be made available to social networks such as Facebook,
                    Instagram & Tiktok.
                </p>
                <p>
                    5.2 If you are logged in on one of the social networks during the
                    visit of one of our websites, the social network might add this
                    information to your profile. If you are interacting with one of the
                    social plug-ins, this information will be transferred to the social
                    network. In case you do not wish such a data transfer, please log off
                    on your social network before you enter one of our websites or mobile
                    or web-based applications.
                </p>
                <p>
                    5.3 We cannot influence this data collection and data transfer via the
                    social plug-ins. Please read the privacy policies of those social
                    networks for detailed information about the collection and transfer of
                    personal data, what rights you have and how you can achieve
                    satisfactory privacy settings.
                </p>
                <h4>6. Web Analytics by Google Analytics</h4>
                <p>
                    6.1 Our website may contain web analytic services provided by Google
                    Analytics. This means that when you visit our website, a cookie will
                    be stored on your computer, except when your browser settings do not
                    allow for such cookies.
                </p>

                <p>
                    6.2 This further means that when you visit our website, the personal
                    data described above in paragraph 1.1 - including the "click-stream
                    data", the data from "web beacons and tracking links" and information
                    stored in Google Analytics' cookies - will be sent to Google Analytics
                    for analysis for and on behalf of us. Please note that if you have
                    created an online profile at our website and if you are logged on in
                    this profile, a unique number identifying this profile will also be
                    sent to Google Analytics in order to be able to match the web
                    analytics data to this profile.
                </p>
                <p>
                    6.3 Google Analytics acts as our agent, which means that we solely
                    determine the purposes for which the data is being used. You can find
                    out more about the relationships between Google Analytics and us in
                    the Google Analytics' privacy policy.
                </p>
                <p>
                    6.4 If you do not wish information about your behavior at our website
                    being collected and assessed by Google Analytics, you can install the
                    Google Analytics opt-out browser add-on. This add-on instructs the
                    Google Analytics JavaScript (ga.js, analytics.js, and dc.js) to not
                    send your site visit information to Google Analytics. The browser
                    add-on is available for most modern browsers. The Google Analytics
                    opt-out browser add-on does not prevent information from being sent to
                    the website itself or in other ways to web analytics services.
                </p>
                <h4>7. Retargeting Technologies</h4>
                <p>
                    7.1 Our website may use retargeting technologies within the Internet.
                    This enables us to show our visitors, who are already interested in
                    our shop and our products, advertisements from us on partner websites.
                </p>
                <p>
                    7.2 Retargeting technologies analyze your cookies and display
                    advertisements based on your past surfing behavior. For further
                    information on Cookies, please refer to paragraph 1 of this Policy.
                </p>
                <p>
                    7.3 We do not store any personal data about you with this technology.
                </p>
                <h4>8. Security</h4>
                <p>
                    We endeavor to take precautions to ensure that the information you
                    have provided is protected against unauthorized or unintended use,
                    access or disclosure. However, we cannot be held responsible for
                    unauthorized or unintended use, access or disclosure that is beyond
                    our control.
                </p>
                <h4>9. Disclaimer</h4>
                <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE IN ANY
                    EVENT FOR ANY SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, INCIDENTAL OR
                    CONSEQUENTIAL DAMAGES OF ANY KIND OR FOR ANY LOSS OF REPUTATION OR
                    GOODWILL, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
                    EQUITY, STRICT LIABILITY, STATUTE OR OTHERWISE, SUFFERED AS A RESULT
                    OF UNAUTHORIZED OR UNINTENDED USE, ACCESS OR DISCLOSURE OF YOUR
                    PERSONAL DATA.
                </p>
                <h4>10. Changes to this Policy</h4>
                <p>
                    We may amend this Policy from time to time. The amended Policy will be
                    published on our website.
                </p>
                <h4>11. Your Rights</h4>
                <p>
                    11.1 If you have any questions about the processing of your personal
                    data or about our Policy, if you do not accept the amended Policy, if
                    you wish to withdraw any consent you have given us at any time, or if
                    you wish to update or have access to your personal data, you are
                    welcome to contact us. The contact details are:
                    <table className="m-auto mt-4" id="right">
                        <tr>
                            <td style={{ width: "30%" }}>Attention to</td>
                            <td>: UB GENESIS Customer Service</td>
                        </tr>
                        <tr>
                            <td style={{ width: "30%" }}>Email </td>
                            <td>: supports@ubgenesis.com</td>
                        </tr>
                        <tr>
                            <td style={{ width: "30%" }}>Telephone number</td>
                            <td>: +65 8726 3155</td>
                        </tr>
                        <tr>
                            <td style={{ width: "30%" }}>Mail</td>
                            <td>
                                : 625 Aljunied Road, Aljunied Industrial Complex <br /> #05-08A,
                                Singapore 389836
                            </td>
                        </tr>
                    </table>
                </p>
                <p>
                    11.2 All requests for correction or for access to your personal data
                    must be in writing. We will endeavor to respond to your request within
                    30 days, and if that is not possible, we will inform you of the time
                    by which we will respond to you.
                </p>
                <p>
                    11.3 We may be prevented by law from complying with any request that
                    you may make. We may also decline any request that you may make if the
                    law permits us to do so.
                </p>
                <p>
                    11.4 In many circumstances, we need to use your personal data in order
                    for us to provide you with products or services which you require or
                    have requested. If you do not provide us with the required personal
                    data, or if you do not accept the amended Policy or withdraw your
                    consent to our use and/or disclosure of your personal data for these
                    purposes, it may not be possible for us to continue to serve you or
                    provide you with the products and services that you require or have
                    requested.
                </p>
                <p>
                    11.5 We may charge you a fee for responding to your request for access
                    to the personal data which we hold about you, or for information about
                    the ways in which we have (or may have) used your personal data. If a
                    fee is to be charged, we will inform you of the amount beforehand and
                    respond to your request after payment is received.
                </p>
                <h4>12. Complaints</h4>
                <p>
                    12.1 You may send complaints regarding our handling of personal data
                    in the following ways:
                </p>
                <p>
                    <strong className="me-2" style={{ fontSize: "1.2rem" }}>
                        &#x2022;
                    </strong>
                    be addressed to the UB GENESIS Customer Service at:
                    <ul className="click-stream ms-4">
                        <li>
                            provide the name, contact number and address of the person
                            submitting the feedback
                        </li>
                        <li>
                            provide the relevant details required such as the relevant time
                            period, our employees or who were involved, or copies of relevant
                            document
                        </li>
                    </ul>
                </p>
                <p>
                    <strong className="me-2" style={{ fontSize: "1.2rem" }}>
                        &#x2022;
                    </strong>
                    in writing or by email. Such complaints must:
                    <ul className="click-stream ms-4">
                        <li>Email : supports@ubgenesis.com</li>
                        <li>
                            Address : 625 Aljunied Road, Aljunied Industrial Complex #05-08A,
                            Singapore 389836
                        </li>
                    </ul>
                </p>
                <p>
                    12.2 All complaints will be evaluated by UB GENESIS Customer Service
                    in a timely manner. After UB GENESIS Customer Service has completed
                    its evaluation, he/she will respond to the person who submitted the
                    complaint or feedback, with the results of the evaluation.
                </p>
                <h4>13. Governing Law</h4>
                <p>
                    This Policy is governed by the laws of Singapore. You agree to submit
                    to the exclusive jurisdiction of the Courts of Singapore in any
                    dispute relating to this Policy.
                </p>
            </div>
            <Footer />
        </>
    );
};

export default TermsAndConditions