import * as React from 'react';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers-pro';
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Header from '../../../layout/Header/Header'
import Footer from '../../../layout/Footer/Footer'
import "./MyOrders.css"
import { Link } from 'react-router-dom';
import image from "../../../assets/images/png/demo-img.png"

const Orders = () => {
    return (
        <>
            <Header />
            <section id="search-header">
                <div className="row justify-content-between px-5 mx-3">
                    <div className="col-lg-6 ps-0">
                        <form className="search" action="#">
                            <input type="text" className="search-input" placeholder="Search..." id='searchText' name="searchKeyword" onkeypress="handle" />
                            <i className="fa fa-search"></i>
                            <input className="search-btn" type="submit" value="products" />
                        </form>
                    </div>
                    <div className="col-lg-4 text-right">
                        <button className="btn-interest text-uppercase">I’M INTERESTED IN MENS CLOTHES</button>
                    </div>
                </div>
            </section>

            <section id='orders'>
                <div className='row px-5 mx-3'>
                    <div className='card'>
                        <div className='p-4'>
                            <h2 className='text-uppercase m-0'>ORDERS</h2>
                        </div>
                        <div className='d-flex justify-content-around mb-3'>
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateRangePicker']}>
                                    <DateRangePicker localeText={{ start: 'Check-in', end: 'Check-out' }} />
                                </DemoContainer>
                            </LocalizationProvider> */}
                            <form className="search" action="#">
                                <input type="text" className="search-input" placeholder="Search tracking #" id='searchText' name="searchKeyword" onkeypress="handle" />
                                <i className="fa fa-search"></i>
                            </form>
                        </div>

                        <table className='table'>
                            <thead>
                                <tr>
                                    <th scope="col" className='text-center'>TRACKING #</th>
                                    <th scope="col" >PRODUCT DETAILS</th>
                                    <th scope="col" className='text-center'>QTY</th>
                                    <th scope="col" className='text-center'>ORDER TOTAL</th>
                                    <th scope="col" className='text-center'>DATE & TIME</th>
                                    <th scope="col" className='text-center'>ORDER STATUS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center'></td>
                                    <td>
                                        <div className='d-flex'>
                                            <img className='img-fluid me-3' src={image} alt='' style={{ width: "80px", height: "100px", borderRadius: "10px" }} />
                                            <div className='d-flex flex-column justify-content-center'>
                                                <p className=''>Order # : UB45</p>
                                                <p className='mb-0'>Bra worn at 3 days</p>
                                                <p className='mb-0'>Seller name: <span style={{ color: "#f47274" }}>abc123</span></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className='text-center'>1</td>
                                    <td className='text-center'>S$80.00</td>
                                    <td className='text-center'>19 Oct 2023 12:23</td>
                                    <td className='text-center'>
                                        <span className="badge badgeInit rounded-pill text-dark">INITIATED</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center'></td>
                                    <td>
                                        <div className='d-flex'>
                                            <img className='img-fluid me-3' src={image} alt='' style={{ width: "80px", height: "100px", borderRadius: "10px" }} />
                                            <div className='d-flex flex-column justify-content-center'>
                                                <p className=''>Order # : UB45</p>
                                                <p className='mb-0'>Bra worn at 3 days</p>
                                                <p className='mb-0'>Seller name: <span style={{ color: "#f47274" }}>abc123</span></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className='text-center'>1</td>
                                    <td className='text-center'>S$80.00</td>
                                    <td className='text-center'>19 Oct 2023 12:23</td>
                                    <td className='d-flex flex-column align-items-center justify-content-center'>
                                        <span className="badge badgeComp rounded-pill text-dark mb-3">Completed</span>
                                        {/* <button className='btn-ninja'>Add Review</button> */}
                                        <button type="button" className="btn-ninja" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Add Review
                                        </button>

                                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h4 className="modal-title" id="exampleModalLabel">ADD REVIEW</h4>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="stars mb-3">
                                                            <i className="fa-solid fa-star fa-lg"></i>
                                                            <i className="fa-solid fa-star fa-lg"></i>
                                                            <i className="fa-solid fa-star fa-lg"></i>
                                                            <i className="fa-solid fa-star fa-lg"></i>
                                                            <i className="fa-solid fa-star fa-lg"></i>
                                                        </div>
                                                        <textarea className="form-control" placeholder='Write your review for the product and sellers here...' rows="3"></textarea>
                                                    </div>
                                                    <div className="modal-footer mb-3">
                                                        <button type="button" className="btn btn-submit text-uppercase">Submit</button>
                                                        <button type="button" className="btn btn-light text-uppercase ms-3" data-bs-dismiss="modal">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Orders