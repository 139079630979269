import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../../../store/GlobalContext';
import { AddToCart } from '../../../../../utils/cart/cartUtils';
import { AddToFavorite } from '../../../../../utils/favorite/favorite';
const FeaturedProducts = () => {
    const { t } = useTranslation('landingpage');
    const { state } = useContext(GlobalContext);
    const Navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);
    const maxLength = 70;
    const featuredProducts = state.featuredProducts ?? []
    const toggleDescription = () => {
        setExpanded(!expanded);
    };
    return (
        <section id="feature-prod" className="feature-prod py-5">
            <div className="container-fluid mt-3" data-aos="fade-up">
                <div className="section-title text-center">
                    <h2 className='mb-0'>{t('heading6')}</h2>
                </div>
                <div className="row px-lg-5 mx-3">
                    {featuredProducts?.map((elem) => (
                        <div
                            key={elem?.id} className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => Navigate(`/product_display_page_men/${elem?.url_link}`)}>
                                    <img src={elem?.image} className="img-fluid image-item" alt="" />
                                </div>
                                <div className="member-info">
                                    <h5 className='mb-0'>{elem?.name}</h5>
                                    <h4>S${elem?.price}</h4>
                                    <div className="description">
                                        {/* <p>{expanded ? elem?.description : elem?.description?.length > maxLength ? `${elem?.description.slice(0, maxLength)}... ` : elem?.description}
                                            {elem?.description?.length > maxLength && (
                                                <button className="toggle-btn" onClick={toggleDescription} style={{ background: "none", border: "none", color: "#F47274", padding: "0", width: "auto", fontSize: "1vw" }}>
                                                    {expanded ? 'Read less' : 'Read more'}
                                                </button>
                                            )}
                                        </p> */}
                                        <p>{elem?.description.substring(0,65)}</p>
                                    </div>
                                    <div className='d-flex align-items-center mb-2'>
                                        <AddToCart id={elem?.id} />
                                        <AddToFavorite id={elem?.id} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='text-center my-4'>
                        <button className='see-more' onClick={() => Navigate('/product_page_men')}>{t('text6')}</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturedProducts;