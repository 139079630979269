import Profile from "../../pages/buyer/Profile/Profile";
import Dashboard from "../../pages/seller/Dashboard/Dashboard";
import SellerProduct from "../../pages/seller/Products/SellerProduct";
import SellerProfilePage from "../../pages/seller/Profile/SellerProfilePage";
import SellerViewProduct from "../../pages/seller/Products/subcomponents/SellerViewProduct/SellerViewProduct";
import SellerOrders from '../../pages/seller/Orders/SellerOrders';
import SellerAbout from '../../pages/seller/About/SellerAbout'
import OrdersInfo from "../../pages/seller/OrderDetails/OrdersInfo";
import MyOrders from "../../pages/buyer/Myorders/MyOrders"
// import MyWishlist from "../../pages/buyer/Wishlist/MyWishlist"
import SellerProductDetails from "../../pages/seller/Products/subcomponents/SellerProductDetails/SellerProductDetails";
import Invoice from "../../pages/seller/InvoicesAndReceipts/invoice"
import ReceiptPdf from "../../pages/seller/InvoicesAndReceipts/receipt_pdf";
import InvoicePdf from "../../pages/seller/InvoicesAndReceipts/invoice_pdf";
import SalesInvoice from "../../pages/seller/InvoicesAndReceipts/SalesInvoice"
import SearchResultsPage from '../../pages/buyer/Search/SearchPage'
import MenProductPage from '../../pages/buyer/ProductPage/MenProductPage'
import WomenProductPage from '../../pages/buyer/ProductPage/WomenProductPage'
import MenProductDetails from '../../pages/buyer/ProductDetails/MenProductDetails'
import WomenProductDetails from '../../pages/buyer/ProductDetails/WomenProductDetails'
import TrackMyOrders from "../../pages/buyer/Trackorders/TrackMyOrders";
import CheckOutForm from "../../pages/buyer/Checkout/Checkout";
import Payment from "../../pages/buyer/Checkout/Payment/Payment";
import ThankYou from "../../pages/buyer/Checkout/ThankYou/ThankYou";
import TrackOrders from "../../pages/buyer/Trackorders/TrackOrders";
import Orders from "../../pages/buyer/Myorders/Orders";
import Wishlist from "../../pages/buyer/Wishlist/Wishlist";
import SellerProductsList from "../../pages/seller/Products/SellerProductsList";
import OrdersList from "../../pages/seller/Orders/OrdersList";
import SellerDashboard from "../../pages/seller/Dashboard/SellerDashboard";
import SellerTotalSales from "../../pages/seller/Dashboard/SellerTotalSales";
import SellerProfile from "../../pages/seller/Profile/SellerProfile";
import AddNewProduct from "../../pages/seller/Products/AddNewProduct";
import ProfileDetails from "../../pages/buyer/Profile/subcomponents/ProfileDetails";
const protectedRoutes = [
    {
        path: "/product_page_men",
        element: <MenProductPage />
    },
    {
        path: "/product_page_women",
        element: <WomenProductPage />
    },
    {
        path: "/product_display_page_men/:id",
        element: <MenProductDetails />
    },
    {
        path: "/product_display_page_women/:id",
        element: <WomenProductDetails />
    },
    {
        path: "/checkout",
        element: <CheckOutForm />
    },
    {
        path: "/Profile",
        element: <Profile />
    },
    {
        path: "/Dash",
        element: <Dashboard />
    },
    // {
    //     path: "/seller/:seller",
    //     element: <SellerProfilePage />
    // },
    {
        path: "/seller/:seller",
        element: <SellerProfile />
    },
    {
        path: "/SellerPro",
        element: <SellerProduct />
    },
    {
        path: "/SellerView",
        element: <SellerViewProduct />
    },
    {
        path: "/Sellerorder",
        element: <SellerOrders />
    },
    {
        path: "/Sellerabout",
        element: <SellerAbout />
    },
    {
        path: "/Ordersinfo",
        element: <OrdersInfo />
    },
    {
        path: "/Myorders",
        element: <MyOrders />
    },
    {
        path: "/Trackorders/:trackingId",
        element: <TrackMyOrders />
    },
    // {
    //     path: "/Wishlist",
    //     element: <MyWishlist />
    // },
    {
        path: "/SellerPD",
        element: <SellerProductDetails />
    },
    {
        path: "/SellerInvoice",
        element: <Invoice />
    },
    {
        path: "/Buyer/receipt/:receipt_id",
        element: <ReceiptPdf />
    },
    {
        path: "/Seller/invoice/:invoice_id",
        element: <InvoicePdf />
    },
    {
        path: "/Seller/SalesInvoice/:invoiceId",
        element: <SalesInvoice />
    },
    {
        path: "/searchpage/:searchQuery",
        element: <SearchResultsPage />
    },
    {
        path: "/payment",
        element: <Payment />
    },
    {
        path: "/thankyou",
        element: <ThankYou />
    },
    {
        path: "/orders",
        element: <Orders />
    },
    {
        path: "/wishlist",
        element: <Wishlist />
    },
    {
        path: "/products_list",
        element: <SellerProductsList />
    },
    {
        path: "/orders_list",
        element: <OrdersList />
    },
    {
        path: "/seller_dashboard",
        element: <SellerDashboard />
    },
    {
        path: "/seller_sales",
        element: <SellerTotalSales />
    },
    {
        path: "/seller_profile",
        element: <SellerProfile />
    },
    {
        path: "/add_product",
        element: <AddNewProduct />
    },
    {
        path: "/profile_details",
        element: <ProfileDetails />
    },
]
export default protectedRoutes;