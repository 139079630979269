import React from "react";
const MyAlert = ({ text, setText}) => {
    return (
        <>
            {text && <div className="alert alert-warning alert-dismissible fade show" role="alert" style={{height:"4rem"}}>
                <p className="errmsg" aria-live="assertive">{text}</p>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setText('')}></button>
            </div>
            }
        </>
    )
}
export default MyAlert;