import React from 'react'
import "./AboutUs.css"
import { useTranslation } from 'react-i18next';
const AboutUs = () => {
    const { t } = useTranslation('about');
    return (
        <section id="about-us">
            <div className="container-fluid my-3" data-aos="fade-up">
                <div className="row px-lg-5 px-sm-5 mx-3">
                    <div className="section-title text-center">
                        <h2 className='text-uppercase'>{t('heading1')}</h2>
                    </div>
                    <p className='desc pb-4'>
                        {t('p1')}
                    </p>
                </div>
            </div>
        </section>
    )
}

export default AboutUs