import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import { Spinner } from "react-bootstrap";
import axios from "../../../../services/api/axios";
import MyAlert from "../../../../components/Alerts/Alert/alert";
import logo from "../../../../assets/images/png/Fantasy_Senses_logo.png"
import './Mainbirthday.css';
import { useTranslation } from "react-i18next";
export default function Mainbirthday() {
    const { t } = useTranslation('registration')
    const [loader, setLoader] = useState(false);
    const Navigate = useNavigate();
    const [username, setUserName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [checkbox, setCheckBox] = useState(false);
    const [checkbox2, setCheckBox2] = useState(false)
    const [text, setText] = useState('');
    const signupData = JSON.parse(localStorage.getItem("signupData"))

    const calculate_age = (dob1) => {
        var today = new Date();
        var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        return age_now;
    }
    const HandleSubmit = async () => {
        setLoader(true)
        const age = calculate_age(dateOfBirth)
        if (age > 20) {
            const body = {
                email: signupData.email,
                password: signupData.password,
                phoneNo: signupData.phoneNo,
                userName: username,
                dateOfBirth: dateOfBirth,
            }
            await axios.post("user/register/", body,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }).then(res => {
                    Navigate('/Login')
                    setLoader(false)
                }).catch(err => {
                    setLoader(false)
                    setText(err.code)
                })
        } else {
            setText("please ensure you are 21 or above")
            setLoader(false)
        }
    }
    return (
        <section id="birthday" className="scroll_form">
            <div className="container py-5 forms-container">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <MyAlert text={text} setText={setText} />
                        <div className="card shadow text-white" style={{ borderradius: "0.5rem", height: "45rem" }}>
                            <div className="card-body p-lg-5 p-md-5 p-4git">

                                <div className="mb-md-5">
                                    <div className='text-center mb-3'>
                                        <img src={logo} className="img-fluid" alt="Fantasy Senses" />
                                    </div>
                                    {/* <h2 className="fw-bold mb-2 mb-5" style={{ color: "#f6ecd0" }}>Input your Birthday</h2> */}
                                    <p className="text-center text-white mt-3 mb-5">{t('text19')}</p>
                                    <div className="form-outline form-white mb-5">
                                        {/* <label className="form-label" htmlFor="typeEmailX">Birthday (please ensure you are 21 or above)</label> */}
                                        <input
                                            type="text"
                                            id="typeOtpX"
                                            className="form-control mb-3"
                                            placeholder={t('label5')}
                                            autoComplete="off"
                                            onChange={(e) => setUserName(e.target.value.replace(/ +?/g, ''))}
                                            required />

                                        <input
                                            type="date"
                                            className="form-control input_bar mb-3"
                                            id="inputEmail4"
                                            placeholder="Date of Birth"
                                            value={dateOfBirth}
                                            name={dateOfBirth}
                                            onChange={(e) => setDateOfBirth(e.target.value)}
                                        />

                                    </div>

                                    <div className="form-check form-check-info text-start mb-3">
                                        <Checkbox
                                            sx={{
                                                color: "white",
                                                '&.Mui-checked': {
                                                    color: "white",
                                                },
                                            }}
                                            className="form-check-input"
                                            checked={checkbox}
                                            onChange={() => setCheckBox(!checkbox)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <label className="form-check-label ps-2" htmlFor="flexCheckDefault">
                                            {t('checkbox1')}
                                        </label>
                                    </div>

                                    <div className="form-check form-check-info text-start">
                                        <Checkbox
                                            sx={{
                                                color: "white",
                                                '&.Mui-checked': {
                                                    color: "white",
                                                },
                                            }}
                                            className="form-check-input"
                                            checked={checkbox2}
                                            onChange={() => setCheckBox2(!checkbox2)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <label className="form-check-label ps-2" htmlFor="flexCheckDefault">
                                            {t('checkbox2')} <Link to="/" className="font-weight-bolder a-style" style={{ color: "#F47274" }}>{t('p6')}</Link>
                                            {/* and <Link to="/" className="font-weight-bolder a-style"> Privacy Policy</Link> set by us and abide by the law of XXX. */}
                                        </label>
                                    </div>

                                    <div className="text-center mb-5 position">
                                        <button onClick={HandleSubmit} className="birth-btn mb-3" type="button" disabled={!checkbox || !checkbox2 || !dateOfBirth || username?.length < 3}>
                                            {loader ? <Spinner animation="border" style={{ width: "1.4rem", height: "1.4rem" }} role="status" className="spinner-set">
                                                <span className="visually-hidden">{t('text3')}</span>
                                            </Spinner> :
                                                t('btn1')}
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}