import React, { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Cookies from "universal-cookie";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
const cookies = new Cookies();
const TrackMyOrder = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const [trackingNumber, setTrackingNumber] = useState('');
    const [trackingNoError, setTrackingNoError] = useState(false);
    const validateTrackingNumber = () => {
        if (Object.keys(orders).indexOf(trackingNumber) > -1) {
            setTrackingNoError(false)
            navigate('/Trackorders', { state: { id: trackingNumber } })
        }
        else {
            setTrackingNoError(true)
        }
    }
    useEffect(() => {
        axiosPrivate.get('product/orders_items_list/',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            setOrders(res.data)
        }).catch(err => {
        })
    }, [axiosPrivate])
    return (
        <Dropdown className='track-order-drop2 pt-lg-0 pt-md-4 pt-3 '>

            <Dropdown.Toggle variant="" id="dropdown-basic">
                Track My Order
            </Dropdown.Toggle>

            <Dropdown.Menu id="Order-dropdown" className='shadow'>
                <div className='p-4 '>
                    <form className="row g-3 tool_tip">
                        <h6>My Recent Orders</h6>
                        <span onClick={() => {
                            navigate('/Trackorders', { state: { id: Object.keys(orders)[0] } })
                        }} className="mb-3" style={{ cursor: "pointer", color: "#E86669" }}>{Object.keys(orders)[0] ? <> {Object.keys(orders)[0]}<br />{Object.values(orders)[0]?.date[0]} </> : <p>No recent Orders</p>}</span>
                        <h6>Track my Order</h6>

                        <div className='position-relative mt-2'>

                            <div className="col-12">
                                <label htmlFor="inputPassword4" className="form-label">Your order number:</label>
                                <input
                                    type="text"
                                    placeholder='eg.123456789'
                                    className="form-control recent-orders-input recent-orders-input1"
                                    id="inputPassword4"
                                    value={trackingNumber}
                                    name={trackingNumber}
                                    onChange={(e) => setTrackingNumber(e.target.value)}
                                />
                            </div>

                            <Button
                                onClick={() => validateTrackingNumber()}
                                className='' id="btn-btn-submit"><i className="fa-solid fa-angle-right"></i></Button>

                        </div>
                        {trackingNoError && <span style={{ color: "#E86669" }}>Tracking Number does not exist!</span>}
                    </form>
                </div>

            </Dropdown.Menu>
        </Dropdown>

    )
}
export default TrackMyOrder;