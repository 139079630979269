import React, { useEffect, useState } from "react";
// import { MDBDataTable } from 'mdbreact';
import "../Dashboard/Dashboard.css"
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import $ from 'jquery';
import Dropdown from 'react-bootstrap/Dropdown';
import MyAlert from "../../../components/Alerts/Alert/alert";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Cookies from "universal-cookie";
import DashboardHeader from "../Dashboard/subcomponents/DashboardHeader";
import LoadingBar from 'react-top-loading-bar'
import { Link } from "react-router-dom";
import { WeeklySalesData } from "./subcomponents/WeeklySalesData"
const cookies = new Cookies();
const Invoice = () => {
    const [paynow, setPaynow] = useState(true);
    const [showPage, setShowPage] = useState(false)
    const [progress, setProgress] = useState(30)
    const axiosPrivate = useAxiosPrivate();
    const [errMsg, setErrMsg] = useState();
    // const [setOrders] = useState();
    const [orderList, setOrderList] = useState([]);
    const [orderState, setOrderState] = useState(true);
    const [text, setText] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [bankAccountName, setBankAccountName] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [sales, setSales] = useState([]);
    const [weekStartDate, setWeekStartDate] = useState('');
    const [weekEndDate, setWeekEndDate] = useState('');
    const [adminFee, setAdminFee] = useState('');
    const [payoutAmount, setPayoutAmount] = useState('');
    const [orderIds, setOrderIds] = useState('');
    const [payoutStartDate, setPayoutStartDate] = useState('')
    const [payoutEndDate, setPayoutEndDate] = useState('')
    const [totalSale, setTotalSale] = useState("")
    const weekData = WeeklySalesData();
    console.log(weekData, 'WeeklySalesData')
    useEffect(() => {
        axiosPrivate.get("seller/orders_listing/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            setOrderList(res.data)
            // setOrders(res.data.length)
            setProgress(100)
            setShowPage(true)
        }).catch(err => {
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderState, axiosPrivate])
    useEffect(() => {
        axiosPrivate.get("seller/sales/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            console.log(res.data, "data")
            setSales(res.data)
            setProgress(100)
            setShowPage(true)
        }).catch(err => {
        })
    }, [orderState, axiosPrivate])
    const str = []
    for (let j = 0; j < orderList?.length; j++) {
        str.push(orderList[j].buyer_name)

    }
    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        //replace the month
        format = format?.replace("MM", month.toString().padStart(2, "0"));

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format?.replace("yyyy", year.toString());
        } else if (format?.indexOf("yy") > -1) {
            format = format?.replace("yy", year.toString().substr(2, 2));
        }

        //replace the day
        format = format?.replace("dd", day.toString().padStart(2, "0"));

        return format;
    }

    const grouped = sales?.results?.map((elem) => {
        let date = new Date(elem.date_created)
        return {
            start: convert(startOfWeek(date, { weekStartsOn: 1 })),
            end: convert(endOfWeek(date, { weekStartsOn: 1 }))
        }
    })
    const uniqueIds = [];

    const uniqueWeeks = grouped?.filter(element => {
        const isDuplicate = uniqueIds?.includes(element?.start);

        if (!isDuplicate) {
            uniqueIds.push(element?.start);

            return true;
        }

        return false;
    });
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
    const getWeeklyData = () => {
        let arr = []
        for (let i = 0; i < uniqueWeeks?.length; i++) {
            let body = sales?.results?.filter(elem => convert(elem?.date_created) >= convert(uniqueWeeks[i]?.start) && convert(elem?.date_created) <= convert(uniqueWeeks[i]?.end))
            var total = 0
            var adminFee = 0
            var someDate = new Date(uniqueWeeks[i]?.end);
            var numberOfDaysToAdd = 10;
            var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            var date1 = new Date(result);
            var date2 = new Date();
            var Difference_In_Time = date1.getTime() - date2.getTime();
            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            for (let i = 0, _len = body?.length; i < _len; i++) {
                total += body[i]?.total_sale
                adminFee += body[i]?.admin_fee
            }
            let data = {
                startDate: uniqueWeeks[i]?.start,
                endDate: uniqueWeeks[i]?.end,
                results: body,
                totalSales: total,
                adminFee: adminFee,
                payoutAmount: total - adminFee,
                days: parseInt(Difference_In_Days)
            }
            arr.push(data)
        }
        return arr;
    }
    const Paynow = () => {
        setErrMsg("")
        let body = {
            name: name,
            phone_no: phone,
            payout_method: "PAYNOW",
            order_ids: orderIds,
            week_start_date: weekStartDate,
            week_end_date: weekEndDate,
            admin_fee: adminFee,
            payout_amount: payoutAmount,
            bank_name: "",
            bank_account_name: "",
            bank_account_number: "",
            total_sale: totalSale,
        }
        if (name && phone && phone.length < 8) {
            setErrMsg("phone number must be of 8 numbers")
        }
        else if (name && phone) {
            axiosPrivate.post(`seller/create_payout/`, body,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            ).then(res => {
                setText("Payout is done Successfully!")
                setOrderState(!orderState)
                $("#closeModal").click()
            }).catch(err => {
                setErrMsg(err.code)
            })
        }
        else {
            setErrMsg("Fill All the fields first")
        }
    }
    const BankTransfer = () => {
        let body = {
            name: name,
            phone_no: "",
            payout_method: "",
            order_ids: orderIds,
            week_start_date: weekStartDate,
            week_end_date: weekEndDate,
            admin_fee: adminFee,
            payout_amount: payoutAmount,
            bank_name: bankName,
            bank_account_name: bankAccountName,
            bank_account_number: bankAccountNumber,
            total_sale: totalSale,
        }
        axiosPrivate.post(`seller/create_payout/`, body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            setOrderState(!orderState)
            setText("Payout is done Successfully!")
            $("#closeModal").click()
        }).catch(err => {
            setErrMsg(err.code)
        })
    }
    const mobileViewData = getWeeklyData()?.map((elem, index) => {

        return (
            <>
                <div key={index} className="col-12" style={{ border: "0.15rem solid #17a2b8" }}>
                    {/* <div className=" col-12 "></div> */}
                    <div className='p-3 Myorders-pkg-1 shadow card' style={{ backgroundColor: "black" }}>
                        {/* <div className='Track-order-pkg d-flex justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <span style={{ color: "#E86669", cursor: 'pointer' }}>Date : {elem?.startDate} to {elem?.endDate}</span>
                                    <small></small>
                                </div>
                            </div> */}

                        {/* <hr /> */}

                        <div>
                            <div className="col-12">
                                <p>Orders : {elem.results?.map((el, i) => {
                                    return "#" + el?.order_id + `${elem.results[i + 1] ? ", " : ""}`
                                })}
                                </p>
                                <p>Order Date: {dateFormat(elem?.startDate, 'dd-MM-yyyy')} to {dateFormat(elem?.endDate, 'dd-MM-yyyy')}</p>
                                <p>{elem?.buyer_name}</p>
                            </div>

                            <div className='col-12 d-flex  p-lg-3 p-md-3 p-4 ps-lg-3 ps-md-3 ps-0 '>
                                <div className="col-8 d-flex">
                                    {/* <div className=" img-wrap" >
                                                    <Link to={"/"}><img src={`https://server.fantasysenses.com/media/${el?.product_image}`} alt='not found' /></Link>
                                                </div> */}
                                    &nbsp;
                                    <div className="d-flex flex-column">
                                        <Link to="" className="txt-txt">Total Sale: {elem?.totalSales}
                                        </Link>
                                        <p>Admin Fee: {elem?.adminFee}</p>
                                        <p>Payout Amount: {elem?.payoutAmount}</p>
                                        <div className={elem.days < 0 ? "badge bg-success" : "badge bg-warning"} style={{ color: elem.days < 0 ? "white" : "black" }}>
                                            {elem?.results[0]?.payout_status !== "No payout exist" ? <center>{elem?.results[0]?.payout_status}</center> : elem?.days <= 0 ? "you are elgible for payout" : `Eligible for Payout in ${elem.days} days`}
                                        </div>
                                    </div>
                                </div>


                                <div className="col-4">
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-button-basic" style={{ cursor: 'pointer', backgroundColor: "#E86669", fontSize: "1rem" }}>
                                            Select Action
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='dropdown-menu' style={{ backgroundColor: "black" }}>

                                            <Dropdown.Item className='myWishlist mb-1'>
                                                <>{elem?.results[0]?.payout_status === "PENDING" || elem?.results[0]?.payout_status === "PROCESSING"
                                                    ? ""
                                                    : elem?.results[0]?.payout_status === "PAID" ?
                                                        <Link to={''} onClick={() => window.open(`/seller/SalesInvoice/${index + 1}`)}>{"Invoice"}</Link>
                                                        : elem?.days < 0
                                                        && <div className="text-center"><button className="btn btn-secondary btn-sm" onClick={() => {
                                                            let arr = []
                                                            for (let i = 0; i < elem?.results?.length; i++) {
                                                                arr.push(elem?.results[i]?.order_id)
                                                            }
                                                            setWeekEndDate(elem?.endDate)
                                                            setWeekStartDate(elem?.startDate)
                                                            setAdminFee(elem?.adminFee)
                                                            setPayoutAmount(elem?.payoutAmount)
                                                            setOrderIds(arr)
                                                            setPayoutStartDate(dateFormat(elem?.startDate, 'dd-MM-yyyy'))
                                                            setPayoutEndDate(dateFormat(elem?.endDate, 'dd-MM-yyyy'))
                                                            setTotalSale(parseInt(elem?.totalSales).toFixed(2))
                                                        }
                                                        } data-bs-toggle="modal" data-bs-target="#staticBackdrop" disabled={elem?.results[0]?.payout_status === 'PAID' || elem?.results[0]?.payout_status === 'PENDING' || elem?.results[0]?.payout_status === 'PROCESSING'}>Request For Payout</button></div>
                                                }
                                                </>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    })
    const userAttributes = []
    getWeeklyData()?.map((el, index) => {
        userAttributes.push({
            select: <input id="check1" type="checkbox" />,
            date: <span searchvalue={'tourist'} style={{ cursor: 'pointer' }}><strong><div>{dateFormat(el?.startDate, 'dd-MM-yyyy')}</div><div style={{ textAlign: "center" }}> to </div><div>{dateFormat(el?.endDate, 'dd-MM-yyyy')}</div></strong></span>,
            order: el.results?.map((elem, i) => {
                return "#" + elem?.order_id + `${el.results[i + 1] ? ", " : ""}`
            }),
            sale: <center>{parseInt(el?.totalSales).toFixed(2)}</center>,
            adminfee: <center>{parseInt(el?.adminFee).toFixed(2)}</center>,
            payoutamount: <center>{parseInt(el?.payoutAmount).toFixed(2)}</center>,
            // totalsale: parseInt(el.total_sale).toFixed(2),
            status: <div className={el.days < 0 ? "badge bg-success" : "badge bg-warning"} style={{ color: el.days < 0 ? "white" : "black" }}>
                {el?.results[0]?.payout_status !== "No payout exist" ? <center>{el?.results[0]?.payout_status}</center> : el?.days <= 0 ? "you are elgible for payout" : `Eligible for Payout in ${el.days} days`}
            </div>,
            action: <>{el?.results[0]?.payout_status === "PENDING" || el?.results[0]?.payout_status === "PROCESSING"
                ? ""
                : el?.results[0]?.payout_status === "PAID" ?
                    <Link to={''} onClick={() => window.open(`/seller/SalesInvoice/${index + 1}`)}>{"Invoice"}</Link>
                    : el?.days < 0
                    && <div className="text-center"><button className="btn btn-secondary btn-sm" onClick={() => {
                        let arr = []
                        for (let i = 0; i < el?.results?.length; i++) {
                            arr.push(el?.results[i]?.order_id)
                        }
                        setWeekEndDate(el?.endDate)
                        setWeekStartDate(el?.startDate)
                        setAdminFee(el?.adminFee)
                        setPayoutAmount(el?.payoutAmount)
                        setOrderIds(arr)
                        setPayoutStartDate(dateFormat(el?.startDate, 'dd-MM-yyyy'))
                        setPayoutEndDate(dateFormat(el?.endDate, 'dd-MM-yyyy'))
                        setTotalSale(parseInt(el?.totalSales).toFixed(2))
                    }
                    } data-bs-toggle="modal" data-bs-target="#staticBackdrop" disabled={el?.results[0]?.payout_status === 'PAID' || el?.results[0]?.payout_status === 'PENDING' || el?.results[0]?.payout_status === 'PROCESSING'}>Request For Payout</button></div>
            }
            </>,
            // invoiceLink: <Link to={`/seller/invoice/${el.invoice_id}`}>{el.invoice_id && "Invoice"}</Link>,

        })
        return;
    });

    const data = {
        columns: [
            {
                label: <button className="btn btn-secondary" onClick={() => {
                    document.getElementById("check1").checked ?
                        document.getElementById("check1").checked = false :
                        document.getElementById("check1").checked = true
                }}>Select All</button>,
                field: "select",
                // sort: "asc",
                width: 150
            },
            {
                label: "Date",
                field: "date",
                // sort: "asc",
                width: 150
            },
            {
                label: "Order#",
                field: "order",
                // sort: "asc",
                width: 100
            },
            {
                label: "Total Sale (Weekly)",
                field: "sale",
                // sort: "asc",
                width: 100
            },
            {
                label: "Admin Fee",
                field: "adminfee",
                // sort: "asc",
                width: 150
            },
            {
                label: "Payout Amount",
                field: "payoutamount",
                // sort: "asc",
                width: 150
            },
            {
                label: "Payout Status",
                field: "status",
                // sort: "asc",
                width: 270
            },
            {
                label: "Action",
                field: "action",
                // sort: "asc",
                width: 270
            },
        ],
        rows: userAttributes
    };


    return (
        <>
            <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
            {showPage === true && <>
                <DashboardHeader />
                <div className="container mt-5" id="dash_br">
                    <div className="row g-3" id="dashboard">
                        <MyAlert text={text} setText={setText} />
                        <div className="col-lg-3 col-md-6 col-12 pe-lg-2 text-center">
                            <div className="shadow py-4 px-3 Seller-dash-info position-relative">
                                <div>
                                    <h3 className="mb-0">Weekly Total Sale</h3>
                                    {sales?.start_date && <small>({dateFormat(sales?.start_date, 'dd-MM-yyyy')} to {dateFormat(sales?.end_date, 'dd-MM-yyyy')} )</small>}
                                    <h4 id="b-red" className="mt-4"><b>SGD {sales?.current_week_total_sale ? parseInt(sales?.current_week_total_sale).toFixed(2) : "0.00"}</b></h4>
                                </div>
                                {/* <button type="button" className="btn btn-request requestBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop">REQUEST FOR PAYOUT</button> */}
                                {/* Model */}
                                <div className="modal fade" id="staticBackdrop" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                        <div className="modal-content">
                                            <div className="modal-header request-payout">
                                                <h5 className="modal-title col-9 text-start" id="staticBackdropLabel">You are requesting payout of the Total Sale for this week <strong>({payoutStartDate} to {payoutEndDate})</strong></h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>

                                            <div className="modal-body row gy-3">
                                                <MyAlert text={errMsg} setText={setErrMsg} />
                                                <div className="col-lg-6 col-md-6 col-12 mb-3 text-start">

                                                    <label htmlFor="inputEmail4" className="form-label">Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control input_bar "
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        name={name}
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />

                                                </div>
                                                {/* <div className="col-lg-6 col-md-6 col-12 mb-3 text-start">

                                                    <label htmlFor="inputEmail4" className="form-label">Username:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control input_bar "
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        name={username}
                                                        value={username}
                                                        onChange={(e) => setUserName(e.target.value)}
                                                    />

                                                </div> */}
                                                <div className="text-start">
                                                    <label htmlFor="inputEmail4" className="form-label">Mode of Payout</label>
                                                    <div className="d-flex">
                                                        <button type="button" className="btn btn-paynow col-4 me-3" value="paynow" style={{ backgroundColor: !paynow && "#686868" }} onClick={() => setPaynow(true)}>PAYNOW</button>
                                                        <button type="button" className="btn btn-bank col-4" value="bank_transfer" style={{ backgroundColor: paynow && "#686868" }} onClick={() => setPaynow(false)}>BANK TRANSFER</button>
                                                    </div>
                                                </div>
                                                {paynow ? <div className="col-lg-7 col-md-7 col-12 mb-3 text-start show-hide" id="paynow">

                                                    <label htmlFor="inputEmail4" className="form-label">Phone Number:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control input_bar "
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        name={phone}
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value.replace(/[^+0-9]/g, ""))}
                                                    />

                                                </div>
                                                    :
                                                    <div>
                                                        <div className="col-lg-7 col-md-7 col-12 mb-3 text-start">

                                                            <label htmlFor="inputEmail4" className="form-label">Bank Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control input_bar "
                                                                aria-label="Username"
                                                                aria-describedby="basic-addon1"
                                                                name={bankName}
                                                                value={bankName}
                                                                onChange={(e) => setBankName(e.target.value)}
                                                            />

                                                        </div>
                                                        <div className="col-lg-7 col-md-7 col-12 mb-3 text-start">

                                                            <label htmlFor="inputEmail4" className="form-label">Bank Account Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control input_bar "
                                                                aria-label="Username"
                                                                aria-describedby="basic-addon1"
                                                                name={bankAccountName}
                                                                value={bankAccountName}
                                                                onChange={(e) => setBankAccountName(e.target.value)}
                                                            />

                                                        </div>
                                                        <div className="col-lg-7 col-md-7 col-12 mb-3 text-start">

                                                            <label htmlFor="inputEmail4" className="form-label">Bank Account Number</label>
                                                            <input
                                                                type="text"
                                                                className="form-control input_bar "
                                                                aria-label="Username"
                                                                aria-describedby="basic-addon1"
                                                                name={bankAccountNumber}
                                                                value={bankAccountNumber}
                                                                onChange={(e) => setBankAccountNumber(e.target.value)}
                                                            />

                                                        </div>
                                                    </div>
                                                }
                                                <h6 className="text-start">By submitting this form, I hereby confirm that the provided information is correct</h6>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary" onClick={paynow ? Paynow : BankTransfer}>Submit</button>
                                                <button id="closeModal" type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Model */}
                            </div>
                        </div>



                        <div className="col-lg-3 col-md-6 col-12  px-lg-2 text-center">
                            <div className="shadow p-4  Seller-dash-info">
                                <div>
                                    <h3>Monthly Sales</h3>
                                    <h4 className="mt-4"><b>SGD {sales?.current_month_total_sale ? parseInt(sales?.current_month_total_sale).toFixed(2) : "0.00"}</b></h4>

                                </div>
                                {/* <div className="d-flex align-items-center">
                                    <i className="fa-solid fa-boxes-packing Seller-dash-icons"></i>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12  ps-lg-2 text-center">
                            <div className="shadow p-4 Seller-dash-info">
                                <div>
                                    <h3>Lifetime Sales</h3>
                                    <h4 className="mt-4"><b>SGD {sales?.total_sale ? parseInt(sales?.total_sale).toFixed(2) : "0.00"}</b></h4>
                                </div>
                                {/* <div className="d-flex align-items-center">
                                    <i className="fa-solid fa-users Seller-dash-icons"></i>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12  ps-lg-2 text-center">
                            <div className="shadow p-4 Seller-dash-info">
                                <div>
                                    <h3>Total Payout</h3>
                                    <h4 className="mt-4"><b>SGD {sales?.total_payout ? parseInt(sales?.total_payout).toFixed(2) : "0.00"}</b></h4>
                                </div>
                                {/* <div className="d-flex align-items-center">
                                    <i className="fa-solid fa-users Seller-dash-icons"></i>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>



                <div className="container dash-table" id="Seller_product">
                    <div className="card shadow p-3" >
                        {/* <div className="card-header d-flex justify-content-between"> */}
                        <div className="px-3">
                            <button type="button" className="btn btn-request downBtn">
                                DOWNLOAD SELECTED INVOICES
                            </button>
                        </div>
                        <div className="card-body px-3">
                            {/* <MDBDataTable
                                responsive
                                bordered
                                data={data}
                                sortRows={[' order, status']}
                            /> */}
                        </div>
                        <p>* Request for Payout for weekly sales will be available in ten days after the completion of the week.</p>
                    </div>
                </div>

                <div className="container mobile-view-track my-4">
                    {mobileViewData}
                </div>


            </>
            }
        </>
    );
}

export default Invoice;
