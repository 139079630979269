import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../Signup/subcomponents/Mainsignup.css";
import Spinner from 'react-bootstrap/Spinner';
import MyAlert from "../../../../components/Alerts/Alert/alert";
import axios from "../../../../services/api/axios";
import { useTranslation } from "react-i18next";
const URL = "/auth/validate_username/";
export default function Mainusername() {
    const { t } = useTranslation('registration')
    const [username, setUserName] = useState();
    const [loader, setLoader] = useState(false);
    const Navigate = useNavigate();
    const signupData = JSON.parse(localStorage.getItem("signupData"))
    const [text, setText] = useState(false);
    const HandleSubmit = async () => {
        setLoader(true)
        try {
            const response = await axios.post(URL, { username: username },
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            )
            console.log(response)
            setLoader(false)
            localStorage.setItem("signupData", JSON.stringify({
                email: signupData.email,
                password: signupData.password,
                phoneNo: signupData.phoneNo,
                username: username
            }))
            Navigate("/birthdate");
        } catch (err) {
            setLoader(false)
            if (err?.response?.status === 406) {
                setText("Username is Taken")
            } else {
                setText(err.code)
            }
        }
    }
    return (
        <section className="scroll_form">
            <div className="container py-5 forms-container">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <MyAlert text={text}
                            setText={setText} />
                        <div className="card shadow text-white" style={{ borderradius: "0.5rem", height: "40rem" }}>
                            <div className="card-body p-lg-5 p-md-5 p-4">
                                <div className="mb-md-5">
                                    <h2 className="fw-bold mb-2 mb-5" style={{ color: "#f6ecd0" }}>{t('heading2')}</h2>
                                    {/*<!-- <p className="text-white-50 mb-5">Enter your email and password to register</p> -->*/}

                                    <div className="form-outline form-white mb-5">
                                        <label className="form-label" htmlFor="typeEmailX">{t('label5')}</label>
                                        <input
                                            type="text"
                                            id="typeOtpX"
                                            className="form-control form-control-lg"
                                            autoComplete="off"
                                            onChange={(e) => setUserName(e.target.value.replace(/ +?/g, ''))}
                                            required />
                                    </div>

                                    <p>{t('p4')}</p>

                                    <div className="text-right mb-5 me-5  position">
                                        {loader && <Spinner animation="border" style={{ width: "1.4rem", height: "1.4rem" }} role="status" className="spinner-set">
                                            <span className="visually-hidden">{t('text3')}</span>
                                        </Spinner>}
                                        <button onClick={() => { HandleSubmit("Abdul Qadeer") }} className="btn btn-next  px-5" type="submit" disabled={!username}>{t('btn')}</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}