import React, { useContext, useEffect } from "react"
import { AuthProvider } from "./context/AuthProvider"
import { BrowserRouter, Route, Routes, } from "react-router-dom"
import RequireAuth from "./hooks/RequireAuth"
import unProtectedRoutes from "./routers/Routes/unprotected";
import protectedRoutes from "./routers/Routes/protected";
import AOS from 'aos';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import AppWrapper from "./AppWrapper"
import newroutes from "./routers/Routes/newroutes";
import { GlobalContext } from "./store/GlobalContext";
export default function App() {
  const { dispatch } = useContext(GlobalContext)
  useEffect(() => {
    const initAOS = () => {
      AOS.init({
        duration: 1000,
        easing: 'ease-in-out',
        once: true,
        mirror: false
      });
    };

    const isLoggedIn = window.localStorage.getItem("isLoggedIn")
    dispatch({
      type: "IS_LOGGED_IN",
      payload: isLoggedIn
    })
    // Add event listener to ensure AOS is initialized after the page has fully loaded
    window.addEventListener('load', initAOS);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('load', initAOS);
    };
  }, []);
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <AppWrapper>
            <Routes>
              {/*Authorized*/}
              <Route element={<RequireAuth />}>
                {protectedRoutes?.map(el => <Route path={el?.path} element={el?.element} />)}
              </Route>
              {/*Unauthorized*/}
              {unProtectedRoutes?.map(elem => <Route path={elem?.path} element={elem?.element} />)}
              {newroutes?.map(elem => <Route path={elem?.path} element={elem?.element} />)}
            </Routes>
          </AppWrapper>
        </AuthProvider>
      </BrowserRouter>

    </>
  )
}