import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import SellerProductDetails from '../../../pages/seller/Products/subcomponents/SellerProductDetails/SellerProductDetails';
import CloseButton from 'react-bootstrap/CloseButton';

const ProductModel = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <i className='fa-regular fa-eye fa-lg p-2' variant="primary" onClick={handleShow}>
            </i>

            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg" className="ps-0">
                <Modal.Body style={{ background: "" }} className="p-lg-5 p-md-5 p-3">
                    <div style={{textAlign:"right"}}><CloseButton onClick={handleClose} variant="dark"/></div>
                    <SellerProductDetails
                        productId={props.productId}
                        handleClose={handleClose} />

                </Modal.Body>
            </Modal>
        </>
    );
}
export default ProductModel;