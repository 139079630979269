import React, { useEffect, useState } from 'react'
import Header from '../../../layout/Header/Header'
import Footer from '../../../layout/Footer/Footer'
import ArticleDescription from './subcomponents/ArticleDescription'
import { useParams } from 'react-router-dom'
import axios from '../../../services/api/axios'
const ViewArticles = () => {
    const { uuid } = useParams();
    const [article, setArticles] = useState();
    useEffect(() => {
        axios.get(`view-blog-detail/?uuid=${uuid}`
        ).then(res => {
            console.log(res)
            setArticles(res?.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])
    return (
        <div>
            <Header />
            <ArticleDescription article={article} />
            <Footer />
        </div>
    )
}

export default ViewArticles