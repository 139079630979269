import React, { useContext } from 'react'
import { GlobalContext } from '../../store/GlobalContext'
import { useNavigate } from 'react-router-dom';
import SearchNavbar from '../SearchNavbar/SearchNavbar';
import { useTranslation } from 'react-i18next';

const SearchAndGenderSection = () => {
    const { t } = useTranslation('sellerdashboard')
    const { state, dispatch } = useContext(GlobalContext);
    const gender = state.productsApiParams.gender ?? "MALE"
    const navigate = useNavigate();
    function handleGenderChange() {
        const tempGender = gender;
        let productsApiParams = { ...state.productsApiParams };
        productsApiParams.gender = gender === "MALE" ? "FEMALE" : "MALE";
        dispatch({
            type: "PRODUCTS_API_PARAMS",
            payload: productsApiParams
        })
        dispatch({
            type: "BUYER_SIDE",
            payload: true
        });
        navigate(tempGender === "FEMALE" ? "/product_page_men" : "/product_page_women")
    }
    return (
        <section id="search-header">
            <div className="row justify-content-between px-lg-5 px-sm-0 mx-3 ">
                <div className="col-lg-6 ps-0">
                    <SearchNavbar />
                </div>
                <div className="col-lg-4 pl-sm-0 pr-sm-0 text-right">
                    <button className="btn-interest text-uppercase" onClick={handleGenderChange}>I’M INTERESTED IN {gender === "MALE" ? "WOMENS" : "MENS"} CLOTHES</button>
                </div>
            </div>
        </section>
    )
}

export default SearchAndGenderSection
