import axios from "../../../../services/api/axios";
import React from "react";
import "./Mainsignup.css"
import logo from "../../../../assets/images/png/Fantasy_Senses_logo.png";
import { useRef, useState, useEffect, } from "react";
import { useNavigate, Link } from "react-router-dom";
import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from 'universal-cookie';
import PhoneInput from 'react-phone-number-input'
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from "react-i18next";
const cookies = new Cookies()
const Email_REGEX = /\S+@\S+\.\S+/;
const password_REGEX = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/;
const REGISTER_URL = '/auth/validate_user/';

export default function Mainsignup() {
    const { t } = useTranslation('registration')
    const [loader, setLoader] = useState(false)
    const Navigate = useNavigate();

    const emailRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [matchPassword, setMatchPassword] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [phoneNo, setPhone] = useState('');

    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        setValidEmail(Email_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidPassword(password_REGEX.test(password));
        setValidMatch(password === matchPassword);
    }, [password, matchPassword])



    useEffect(() => {
        setErrMsg('');
    }, [email, password, matchPassword, phoneNo])
    const handleSubmit = async (e) => {
        e.preventDefault();
        const v1 = Email_REGEX.test(email);
        const v2 = password_REGEX.test(password);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        }
        setLoader(true)
        // localStorage.setItem("signupData",JSON.stringify({email,password,phoneNo}))
        // setLoader(false)
        try {
            const response = await axios.post(REGISTER_URL, {
                email,
                password,
                phoneNo
            },
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            cookies.set("phoneNo", phoneNo)
            //clear state and controlled inputs
            //need value attrib on inputs for this
            cookies.set('phoneNo', phoneNo)
            cookies.set('Otp', response.data?.otp)
            localStorage.setItem("signupData", JSON.stringify({ email, password, phoneNo }))
            setEmail('');
            setPassword('');
            setMatchPassword('');
            setPhone('');
            Navigate("/Otp")
        } catch (err) {
            setLoader(false)
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg(err.response?.data.Error);
            } else {
                err.response?.data?.detail?.phoneNo ?
                    setErrMsg(err.response?.data?.detail?.phoneNo) :
                    setErrMsg(err.response?.data?.detail?.email)
            }
        }
    }
    return (
        <section id="signup">
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                        <div className="card shadow text-white" style={{ borderradius: '0.5rem', minHeight: '45rem' }}>
                            <div className="card-body p-lg-5 p-md-5 p-4">

                                <div className="mb-md-5">
                                    <div className='text-center'>
                                        <img src={logo} className="img-fluid" alt="Fantasy Senses" />
                                    </div>
                                    {/* <h2 className="fw-bold mb-2 text-uppercase" style={{ color: "#f6ecd0" }}>Sign Up</h2> */}
                                    <p className="text-center text-white mt-3 mb-5">{t("p2")}</p>
                                    <div className={errMsg ? "alert alert-danger alert-dismissible fade show" : "offscreen"}>
                                        {errMsg}
                                        <button type="button" className={errMsg ? "btn-close" : "d-none"} onClick={() => setErrMsg('')}></button>
                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        {/* <label className="form-label" htmlFor="email">
                                            Email: &nbsp;
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} className={validEmail ? "valid" : "visually-hidden"} />
                                        </label> */}
                                        <input
                                            type="email"
                                            id="email"
                                            ref={emailRef}
                                            autoComplete="off"
                                            className="form-control mb-3"
                                            value={email}
                                            placeholder={t('label1')}
                                            onChange={(e) => setEmail(e.target.value)}
                                            aria-invalid={validEmail ? "false" : "true"}
                                            aria-describedby="uidnote"
                                            onFocus={() => setEmailFocus(true)}
                                            onBlur={() => setEmailFocus(false)}
                                            required />
                                        <p id="uidnote" style={{ color: "#ff8383" }} className={emailFocus && email && !validEmail ? "instructions" : "visually-hidden"}>
                                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "#ff8383" }} />
                                            <span>&nbsp;</span>({t('text6')}) {t('text7')}<br />
                                            {t('text8')}<br />
                                            {t('text9')}
                                        </p>
                                        {/* <label className="form-label" htmlFor="password">
                                            Password: &nbsp;
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} className={validPassword ? "valid" : "visually-hidden"} />
                                        </label> */}
                                        <input
                                            type="password"
                                            id="typePasswordX"
                                            className="form-control mb-3"
                                            value={password}
                                            placeholder={t('label2')}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                            aria-invalid={validPassword ? "false" : "true"}
                                            aria-describedby="pwdnote"
                                            onFocus={() => setPasswordFocus(true)}
                                            onBlur={() => setPasswordFocus(false)}
                                        />
                                        <p id="pwdnote" style={{ color: "#ff8383" }} className={passwordFocus && password && !validPassword ? "instructions" : "visually-hidden"}>
                                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "#ff8383" }} />
                                            &nbsp;{t('text10')}<br />
                                            {t('text11')}<br />
                                            {t('text12')} <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                        </p>
                                        {/* <label className="form-label" htmlFor="confirm_password">
                                            Confirm Password: &nbsp;
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} className={validMatch && matchPassword ? "valid" : "visually-hidden"} />
                                        </label> */}
                                        <input
                                            type="password"
                                            id="confirm_password"
                                            className="form-control mb-3"
                                            value={matchPassword}
                                            placeholder={t('label3')}
                                            onChange={(e) => setMatchPassword(e.target.value)}
                                            required
                                            aria-invalid={validPassword ? "false" : "true"}
                                            aria-describedby="confirmnote"
                                            onFocus={() => setMatchFocus(true)}
                                            onBlur={() => setMatchFocus(false)}
                                        />
                                        <p id="confirmnote" style={{ color: "#ff8383" }} className={matchFocus && !validMatch ? "instructions" : "visually-hidden"}>
                                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "#ff8383" }} />
                                            &nbsp;{t('text13')}
                                        </p>
                                        {/* <label className="form-label" htmlFor="phoneNo">Phone: &nbsp;</label> */}
                                        <div>
                                            <PhoneInput
                                                id="phoneNo"
                                                className="form-control text-dark"
                                                placeholder={t("placeholder2")}
                                                value={phoneNo}
                                                onChange={setPhone}
                                                style={{ padding: "0", lineHeight: "2" }} />
                                        </div>
                                        {/* <input
                                            type="number"
                                            id="phoneNo"
                                            className="form-control"
                                            value={phoneNo}
                                            onChange={(e) => setPhone(e.target.value)}
                                            required
                                        /> */}

                                        <p>
                                            <br />{t('text14')}<br />
                                            <span className="line">
                                                <Link to={"/login"} >{t('text1')}</Link>
                                            </span>
                                        </p>

                                        {/*<!-- <p className="small mb-5 pb-lg-2"><a className="text-white-50" to="#!">Forgot password?</a></p> -->*/}

                                        {/*<!-- <button className="btn btn-outline-light btn-lg px-5" type="submit">Next</button> -->*/}
                                        <div className="text-center position mb-5">
                                            <button disabled={!validEmail || !validPassword || !validMatch ? true : false} className="SignUp-btn mb-3 ">
                                                {loader ? <Spinner animation="border" style={{ width: "1.4rem", height: "1.4rem" }} role="status" className="spinner-set">
                                                    <span className="visually-hidden">{t('text3')}</span>
                                                </Spinner> : t('text5')}
                                            </button>
                                        </div>
                                    </form>

                                </div>

                                {/* <!-- <div>
                                <p className="mb-0">Don't have an account? <a to="#!" className="text-white-50 fw-bold">Sign
                                        Up</a>
                                </p>
    </div> -->*/}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}